<template>
  <component :is="appVersion"></component>

</template>

<script setup>
import MobileApp from './MobileApp';
import DesktopApp from './DesktopApp';

import {ref, computed, onBeforeUnmount, onBeforeMount} from 'vue';

const windowWidth = ref(0);

const appVersion = computed(() => {
  if (windowWidth.value > 720) {
    return DesktopApp;
  }
  return MobileApp;
});

function onResize() {
  windowWidth.value = window.innerWidth;
}

onBeforeMount(() => {
  windowWidth.value = window.innerWidth;
  window.addEventListener('resize', onResize, {passive: true});

});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize, {passive: true});
});


</script>

<style scoped lang=scss>

</style>
