// Pinia authStore
import {defineStore} from 'pinia'
import {Capacitor} from "@capacitor/core";
import {PushNotifications} from "@capacitor/push-notifications";
import {Device} from '@capacitor/device';
import axios from "axios";
import {useAuthStore} from "@/stores/authStore";
import {get} from 'lodash-es';
import {useIterableStore} from "@/stores/iterableStore";

//import Knock from "@knocklabs/client";


export const useNotificationStore = defineStore({
    id: 'notifications',
    state: () => ({
        all: [],
        registered: false,
        token: '',
        declined: false,
        deviceId: '',
        deviceName: '',
        logs: [],
        knockClient: null,
        knockFeed: null,
        knockPreferences: {},
        iterableMessages: [],
        iterableUserRegistered: false,
        iterableDeviceRegistered: false,
        iterableJwt: '',
        iterableDeviceId: '',
        iterablePushToken: '',
        iterableAPIKey: '',
    }),

    actions: {
        async addListeners() {

            await PushNotifications.addListener('registration', async (token) => {
                console.info('Registration token: ', token);
                const notificationStore = useNotificationStore();
                notificationStore.token = token.value;
                const deviceId = await Device.getId();
                const deviceInfo = await Device.getInfo();
                const deviceName = deviceInfo.name || '';
                const authStore = useAuthStore();

                // @ts-ignore
                axios.post("appuser/pushtoken", {
                    deviceId: deviceId.identifier,
                    deviceName: deviceName,
                    token: notificationStore.token,
                    userId: authStore.userId,
                    memberId: get(authStore, 'authDetails.uuid', '')
                }).then(() => {
                    notificationStore.registered = true;
                    const iterableStore = useIterableStore();
                    iterableStore.registerUserIterable();
                }).catch((e) => {
                    console.log('pushtoken error', e);
                    // @ts-ignore
                    this.logs.push(e);
                });
                const iterableStore = useIterableStore();
                await iterableStore.registerDeviceTokenIterable();
            });

            await PushNotifications.addListener(
                'pushNotificationReceived',
                (notification: any) => {

                    //@ts-ignore
                    this.all.push(notification);
                    //@ts-ignore
                    this.logs.push(notification);
                },
            );
        },


        async registerNotifications() {
            if (!Capacitor.isNativePlatform()) {
                return;
            }
            await this.addListeners();
            const devId = await Device.getId();
            this.deviceId = devId.identifier;
            const devInfo = await Device.getInfo();
            this.deviceName = devInfo.name || '';

            if (!Capacitor.isNativePlatform()) {
                //console.log('not native platform');
                return;
            }
            if (this.declined) {
                //console.log('notifications declined');
                return;
            }
            if (this.registered && this.token) {
                //console.log('notifications already registered');
                return;
            }

            // Request permission to use push notifications
            // iOS will prompt user and return if they granted permission or not

            PushNotifications.requestPermissions().then(result => {
                if (result.receive === 'granted') {
                    console.info('notifications receive permission status is granted');
                    // Register with Apple / Google to receive push via APNS/FCM
                    PushNotifications.register();
                } else {
                    // @ts-ignore
                    //console.log('push notification permissions denied');
                    // @ts-ignore
                    this.logs.push(result);
                }
            }).catch((e) => {
                console.log('push notification permission error', e);

            });


        },

        async fetchToken() {
            //ne
        },

        async knockSetup() {
            // const authStore = useAuthStore();
            // // @ts-ignore
            // this.knockClient = new Knock('pk_test_f6lao_JhRs4nMASLjtsd4ebgqDW9xnacRYnDRkZrDY4');
            // // @ts-ignore
            // this.knockClient.authenticate(authStore.userId);
            // // @ts-ignore
            // this.knockFeed = this.knockClient.feeds.initialize('42377892-a85b-4ea9-b13c-36622e7347ac');
            // // @ts-ignore
            // this.knockFeed.on('items.received.realtime', this.handleKnockMessageReceived)
            //
            // // @ts-ignore
            // const kp = await this.knockClient.preferences.get();
            // console.log(kp);
        },

        async setKnockPreferences() {
            // @ts-ignore
            // await this.knockClient.preferences.set({
            //     channel_types: {email: true, sms: true, push: true},
            // });

        },

        async setKnockMessageSeen(message: any) {
            // @ts-ignore
            //console.log(message);
            // @ts-ignore
            this.knockFeed.markAsSeen(message);
        },

        async handleKnockMessageReceived(messages: any[]) {
            //console.log(messages);
            messages.forEach((a) => {
                this.setKnockMessageSeen(a);
            });
        },

    },

    persist: {
        enabled: true
    },

});
