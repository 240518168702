// index.js
import en from './en.json';
import es from './es-US.json';

export const defaultLocale = 'en';

export const languages = {
    en,
    es
};
