//@ts-ignore
import BrowserRandomGenerator from './BrowserRandomGenerator';
//@ts-ignore
import createAleaGeneratorWithGeneratedSeed from './createAleaGenerator';
//@ts-ignore
import createRandom from './createRandom';

let generator;
if (typeof window !== 'undefined' && window.crypto) {
    generator = new BrowserRandomGenerator();
} else {
    // On IE 10 and below, there's no browser crypto API
    // available. Fall back to Alea
    //
    // XXX looks like at the moment, we use Alea in IE 11 as well,
    // which has `window.msCrypto` instead of `window.crypto`.
    generator = createAleaGeneratorWithGeneratedSeed();
}


export const Random = createRandom(generator);

export default Random;
