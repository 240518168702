<template>
  <div class="desktopMenu">

    <DesktopMenuItem
        route="/"
    >
      <div class="title">{{ $t('menu.home') }}</div>
    </DesktopMenuItem>

    <DesktopMenuItem
        v-if="authStore.userHasModule('procedureShopping')"
        route="/shopping"
    >
      <div class="title">{{ $t('shopping.findCare') }}</div>
    </DesktopMenuItem>

    <DesktopMenuItem
        route="/wallet"
        v-if="authStore.userHasModule('wallet')"
    >
      <div class="title">{{ $t('rewards.rewards') }}</div>
    </DesktopMenuItem>

    <DesktopMenuItem
        route="/profile"
    >
      <div class="title">{{ $t('profile.myProfile') }}</div>
    </DesktopMenuItem>
  </div>

</template>

<script setup>

import DesktopMenuItem from "@/components/DesktopMenuItem.vue";
import {useAuthStore} from '@/stores/authStore';

const authStore = useAuthStore();


</script>

<style scoped lang=scss>
.desktopMenu {
  display: flex;
  margin-right: 2rem;

  .title {
    font-size: 10px;
    white-space: nowrap;
  }
}
</style>
