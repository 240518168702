<template>
  <div class="chat-activator-button"
       v-if="shouldShow"
       @click.exact="toggleChat"
       @click.shift="matrixStore.resetChat">
    <ChatQuestionIcon></ChatQuestionIcon>

    <div class="unreadMessageAccount" v-if="unreadMessages">{{ unreadMessages }}</div>
  </div>

  <transition name="slide-in">
    <div v-if="chatActive" :class="`chat-overlay deep-shadow ${chatLocationClass}`" ref="messagesOverlay">

      <div class="chat-header bottom-shadow">
        Active Chat
        <div class="chat-header-boxes">

          <WindowMinimizeIcon
              @click="changeLocation('bottom-one-third')"
              v-if="chatLocationClass != 'bottom-one-third'"></WindowMinimizeIcon>
          <RectangleOutlineIcon
              @click="changeLocation('bottom-two-thirds')"
              v-if="chatLocationClass != 'bottom-two-thirds'"
          ></RectangleOutlineIcon>

          <SquareOutlineIcon
              @click="changeLocation('fullscreen')"
              v-if="chatLocationClass != 'fullscreen'"
          ></SquareOutlineIcon>

          <CloseCircleIcon
              @click="toggleChat"
          ></CloseCircleIcon>

        </div>
      </div>

      <div class="chat-history" ref="messagesWindow">

        <div class="messageWrapper"
             v-for="message in displayMessages" :key="message.eventId"
             :class="{
              outbound: message.sender === userId,
              inbound: message.sender !== userId
            }"
        >
          <div class="messageSenderWrapper">
            <div class="messageSender" :style="`background-image: url(${getAvatar(message.sender)});`"></div>
          </div>
          <div class="message">
            {{ message.body }}
          </div>

        </div>


      </div>
      <div class="chat-typing" v-if="partnerTyping">
        {{ typingPartner }} is typing...
      </div>
      <div class="chat-input">
        <HercInput
            v-model="messageText"
            :appendIcon="SendCircleIcon"
            @append-icon-clicked="sendMessage"
            @keyup.enter="sendMessage"
            @keydown="isTyping"
        ></HercInput>
      </div>
    </div>
  </transition>

</template>

<script setup>


import {useAuthStore} from "@/stores/authStore";
import {computed, onMounted, ref, watch} from "vue";
import ChatQuestionIcon from "vue-material-design-icons/ChatQuestion.vue";
import SendCircleIcon from "vue-material-design-icons/SendCircle.vue";

import WindowMinimizeIcon from "vue-material-design-icons/WindowMinimize.vue";
import RectangleOutlineIcon from "vue-material-design-icons/RectangleOutline.vue";
import SquareOutlineIcon from "vue-material-design-icons/SquareOutline.vue";
import CloseCircleIcon from "vue-material-design-icons/CloseCircle";

import HercInput from "@/components/HercInput.vue";

import {storeToRefs} from "pinia";
import {useMatrixStore} from "@/stores/matrixStore";
import {onClickOutside} from '@vueuse/core';
import {get} from "lodash-es";

const typingTimer = ref(null);
const lastReportedTypingValue = ref(false);
const authStore = useAuthStore();
const matrixStore = useMatrixStore();
const {
  activeRoomId,
  chatActive,
  messages,
  userId,
  partnerTyping,
  typingPartner,
  unreadMessages,
  userDirectory
} = storeToRefs(matrixStore);
const messageText = ref('');
const messagesWindow = ref(null);
const messagesOverlay = ref(null);
const shouldShow = computed(() => {

  return authStore.userHasModule('megChat');
});

onClickOutside(messagesOverlay, hideChat, {ignore: ['.chat-activator-button']});

const chatLocationClass = computed(() => {
  return matrixStore.chatLocationClass;
});

const displayMessages = computed(() => {
  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
  return messages.value.slice(0).sort((a, b) => {
    return a.timeStamp - b.timeStamp;
  });
});

async function hideChat() {
  chatActive.value = false;
}

function getAvatar(userId) {
  const user = userDirectory.value.find(a => a.userId === userId);
  if (!user || get(user.user, 'http_user_avatar', null) === null) {
    return '/assets/images/defaultprofileimage.png';
  }
  return user.user.http_user_avatar;
}

async function changeLocation(layout) {
  matrixStore.setChatLayout(layout);
  await new Promise(r => setTimeout(r, 10));
  scrollToBottom();
}

async function toggleChat() {
  matrixStore.toggleChat();

  if (chatActive.value) {
    await new Promise(r => setTimeout(r, 520));
    scrollToBottom();
  }

}

function sendMessage() {
  if (!messageText.value) {
    return;
  }
  matrixStore.sendMessage(messageText.value);
  messageText.value = '';
}


function isTyping() {

  clearTimeout(typingTimer.value);
  if (get(window, 'matrixClient', null) === null) {
    return;
  }
  if (!lastReportedTypingValue.value) {
    //@ts-ignore
    window.matrixClient.sendTyping(activeRoomId.value, true, 1000);
    lastReportedTypingValue.value = true;
  }
  typingTimer.value = setTimeout(function () {
    if (get(window, 'matrixClient', null) === null) {
      return;
    }
    //@ts-ignore
    window.matrixClient.sendTyping(activeRoomId.value, false, 1000);
    lastReportedTypingValue.value = false;
  }, 1500);
}

function scrollToBottom() {

  if (get(messagesWindow.value, 'children', false) === false) {
    return;
  }
  messagesWindow.value.children[messagesWindow.value.children.length - 1].scrollIntoView({behavior: 'smooth'});
}

onMounted(() => {
  if (!shouldShow.value) {
    return;
  }
  matrixStore.setup();
});

watch(displayMessages, async () => {
  await new Promise(r => setTimeout(r, 100));
  scrollToBottom();
});

</script>

<style scoped lang=scss>

.chat {
  &-activator-button {
    color: white;
    margin-right: 1rem;
    padding-top: 5px;
    position: relative;

    .unreadMessageAccount {
      position: absolute;
      top: -5px;
      right: -5px;
      background-color: var(--ion-color-danger);
      color: white;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
  }

  &-header {
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    background-color: var(--ion-color-secondary);
    color: var(--ion-color-primary-contrast);
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-boxes {
      span {
        margin-left: 5px;
      }
    }
  }

  &-typing {
    padding: 10px 10px 0;
    font-size: 12px;
    color: var(--ion-color-primary);
  }

  &-overlay {

    position: absolute;

    &.bottom-two-thirds {
      right: 2%;
      width: 96%;
      height: 66%;
      bottom: calc(env(safe-area-inset-bottom) + 55px);

    }

    &.bottom-one-third {
      right: 2%;
      width: 96%;
      height: 33%;
      bottom: calc(env(safe-area-inset-bottom) + 55px);

    }

    &.fullscreen {
      right: 2%;
      width: 96%;
      height: calc(100% - env(safe-area-inset-bottom) - env(safe-area-inset-top));
      bottom: env(safe-area-inset-bottom);

    }

    z-index: 30;

    color: var(--ion-color-primary);

    background-color: var(--ion-color-card-background);


    border-radius: 10px;

    display: flex;
    flex-direction: column;

  }

  &-history {
    flex-grow: 1;
    padding: 10px;
    overflow: scroll;

    .messageWrapper {
      display: flex;
      margin-bottom: 1rem;
      width: 100%;

      .messageSenderWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .messageSender {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          margin-right: 10px;
        }
      }


      &.outbound {
        justify-content: flex-start;
        flex-direction: row-reverse;

        .message {
          background-color: var(--ion-color-primary);
          color: var(--ion-color-primary-contrast);
          position: relative;
          margin-right: 10px;

          &:before {
            content: "";
            width: 0px;
            height: 0px;
            position: absolute;
            border-right: 8px solid var(--ion-color-primary);
            border-left: 8px solid transparent;
            border-top: 8px solid var(--ion-color-primary);
            border-bottom: 10px solid transparent;
            right: 12px;
            bottom: -10px;
          }
        }
      }

      &.inbound {
        justify-content: start;


        .message {
          background-color: var(--ion-color-primary-lighten-3);
          color: var(--ion-color-primary);
          position: relative;

          &:before {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            border-left: 8px solid var(--ion-color-primary-lighten-3);
            border-right: 8px solid transparent;
            border-top: 8px solid var(--ion-color-primary-lighten-3);
            border-bottom: 10px solid transparent;
            left: 12px;
            bottom: -10px;
          }
        }
      }


      .message {
        border-radius: 20px;
        background-color: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast);
        padding: 5px 10px;
        width: fit-content;
        margin-bottom: 14px;
        max-width: 80%;


      }
    }

  }

  &-input {
    padding-left: 10px;
    padding-right: 10px;
  }

}


.slide-in-enter-active {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-leave-active {
  -webkit-animation: slide-out-bottom 0.25s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: slide-out-bottom 0.25s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}


</style>
