// Pinia authStore
// noinspection JSVoidFunctionReturnValueUsed

import {defineStore} from 'pinia'
import * as Sentry from "@sentry/vue";
import simpleddp from "@/plugins/simpleddp";
import shajs from 'sha.js';
import {get} from 'lodash-es';

import Random from '@/modules/random/random';
import axios from "axios";
import {useEventsStore} from "@/stores/eventStore";
import {useNotificationStore} from "@/stores/notificationStore";
import {useIterableStore} from "@/stores/iterableStore";


// @ts-ignore
export const useAuthStore = defineStore({
    id: 'auth',
    state: () => {
        return {
            talonToken: '',
            accumulators: [],
            activeDemoRecord: {},
            activePlanId: '',
            activePlan: {
                name: '',
                identifier: '',
            },
            activePlanDetails: {},
            activeEmployer: {
                name: '',
                identifier: '',
            },
            authDetails: {uuid: ''},
            healthPlanAccounts: [],
            isAuthenticated: false,
            isDependent: false,
            inactiveAt: 0,
            userId: '',
            userData: {},
            token: '',
            tokenExpires: '',
            tpas: [],
            tpaId: '',
            employers: [],
            ddpSubscriptions: {},
            users: [],
            demographicsRecords: [],
            dependents: [],
            modules: {},
            locked: false,
            lockedRoute: '',
            loginFailed: false,
            loginErrorMessage: '',
            email: '',
            password: '',
            credentialsSet: false,
            passwordResetError: '',
            passwordResetMessage: '',
            fetchingModules: false,
            reported: false,
            intendedRoute: '',
            activeAccessGrants: [],
            pendingAccessRequests: [],
            flexpaLinked: false,
            otherMembers: [],
        };
    },

    actions: {
        getApiUrl() {

            const prod = 'https://api.herculeshealth.app/api/';
            const preprod = 'https://preprod.herculeshealth.app/api/';

            // if (process.env.NODE_ENV == 'development') {
            //     return 'http://herculeshealth-app.test/api/';
            // }
            return this.userHasModule('development') ? preprod : prod;
        },

        // @ts-ignore
        storeAppUserDetails(formData) {
            axios.post(`${this.getApiUrl()}member/appuser/storeuser`, formData).then(() => {
                this.reported = true;
            });
        },

        storeAppUser() {
            if (!this.email) {
                this.email = get(this.userData, 'profile.emailPrimary', '');
            }
            if (!this.email) {
                return;
            }

            if (!this.userId) {
                this.userId = get(this.userData, 'id', '');
            }

            if (!this.userId) {
                return;
            }

            axios.post(`${this.getApiUrl()}member/appuser/storeuser`, {
                userId: this.userId,
                email: this.email,
                member_uuid: get(this.authDetails, 'uuid', ''),
                firstName: get(this.userData, 'profile.first_name', ''),
                lastName: get(this.userData, 'profile.last_name', '')
            }).then(() => {
                this.reported = true;
            });
        },
        resume() {
            simpleddp.login({resume: this.talonToken}).then(() => {
                const eventStore = useEventsStore();
                eventStore.recordEvent('resume', '');
                this.fetchUserDetails();
            }).catch(() => {
                this.isAuthenticated = false;
            });
        },

        setupSubscriptions() {
            //@ts-ignore
            // this.ddpSubscriptions.users = simpleddp.subscribe('users');

        },

        async fetchOtherMembers() {
            // @ts-ignore
            this.otherMembers = await axios.get(`${this.getApiUrl()}member/dependents?userId=${this.userId}&uuid=${get(this.authDetails, 'uuid', '')}`).then(({data}) => {
                return data;
            }).catch(() => {
                return [];
            });
        },

        fetchUserDetails() {

            this.employers = simpleddp.collection('employers').fetch();
            this.tpas = simpleddp.collection('thirdPartyAdministrators').fetch();
            this.userData = simpleddp.collection('users').fetch()[0];

            this.demographicsRecords = simpleddp.collection('demographicsFlowDemographics').fetch();

            const d = new Date();
            //@ts-ignore

            const activeDemographicRecord = this.demographicsRecords.find(a => new Date(a.dateOfTermination).getTime() >= d.getTime());
            // @ts-ignore
            this.isDependent = this.userId != activeDemographicRecord.userId;
            //@ts-ignore
            this.activeDemoRecord = activeDemographicRecord;
            if (activeDemographicRecord) {
                //@ts-ignore
                this.dependents = activeDemographicRecord.dependents;

                //@ts-ignore
                this.activePlanId = activeDemographicRecord.planId;

                //@ts-ignore
                this.tpaId = activeDemographicRecord.tpaId;

                //@ts-ignore
                const emp = this.employers.find(e => e.slug == activeDemographicRecord.employerSlug);
                if (emp) {
                    //@ts-ignore
                    this.activeEmployer = emp;
                }
            } else {
                console.log('no active demographic record');
            }

            this.healthPlanAccounts = simpleddp.collection('healthPlanAccounts').fetch();

            //@ts-ignore
            const ap = this.healthPlanAccounts.find(a => a.active);

            if (ap) {
                //@ts-ignore
                this.activePlan = ap.details;
                this.fetchUserModules();
                this.fetchActivePlanDetails().then(() => {
                    //ne
                });
                this.fetchUserAuthDetails();
                if (this.userHasModule('demoAccumulators')) {
                    this.injectDemoData();
                }
            } else {
                console.log('no active plan.')
            }

        },

        fetchUserModules() {
            this.fetchingModules = true;
            const formData = {
                //@ts-ignore
                memberId: this.activePlan.memberId,
                //@ts-ignore
                employerId: this.activePlan.groupId
            };
            axios.post(`${this.getApiUrl()}member/modules`, formData)
                .then(({data}) => {
                    //@ts-ignore
                    this.modules = data;
                    this.fetchingModules = false;
                    if (this.userHasModule('development')) {
                        //console.log('sentry disabled');
                        // @ts-ignore
                        Sentry.getCurrentHub().getClient().getOptions().enabled = false;
                    }
                }).catch(() => {
                this.fetchingModules = false;
            });
        },

        fetchUserAuthDetails() {
            const formData = {
                //@ts-ignore
                memberId: this.activePlan.memberId,
                //@ts-ignore
                tpaId: this.tpaId
            };
            axios.post(`${this.getApiUrl()}member/details`, formData).then(({data}) => {
                //@ts-ignore
                this.authDetails = data;
                this.fetchOtherMembers();
            }).catch(() => {
                //ne
            });
        },

        async fetchActivePlanDetails() {
            const formData = {
                //@ts-ignore
                tpaId: this.tpaId,
                //@ts-ignore
                employerId: this.activePlan.groupId,
                //@ts-ignore
                planId: this.activePlanId
            };
            await axios.post(`${this.getApiUrl()}plan/details`, formData).then(({data}) => {
                //@ts-ignore
                this.activePlanDetails = data;
            }).catch(() => {
                //ne
            });
        },

        login() {
            const eventStore = useEventsStore();
            const p = shajs('sha256').update(this.password).digest('hex');

            const loginData = {
                email: this.email,
                password: p,
                sessionKey: Random.secret(),
                code: null
            };

            simpleddp.call("initiateLogin", loginData)
                .then((initiateResult: any) => {
                    console.log('initiate result', initiateResult);
                    Sentry.setUser({email: loginData.email});
                    simpleddp.login({
                        user: {id: initiateResult.user._id},
                        password: {
                            digest: p,
                            algorithm: 'sha-256'
                        }
                    }).then((result: any) => {
                        this.loginErrorMessage = '';
                        this.userId = initiateResult.user._id;
                        this.isAuthenticated = true;
                        this.locked = false;
                        this.talonToken = result.token;
                        this.tokenExpires = result.tokenExpires;
                        this.storeAppUserDetails({
                            userId: this.userId,
                            email: loginData.email
                        });
                        this.fetchUserDetails();

                        const iterableStore = useIterableStore();
                        iterableStore.registerUserIterable().then(() => {
                            //ne
                        });
                        const notificationStore = useNotificationStore();
                        notificationStore.registerNotifications().then(() => {
                            //ne
                        });


                    }).catch((loginError: any) => {
                        eventStore.recordEvent('loginError', JSON.stringify(loginError));
                        this.loginFailed = true;
                        this.loginErrorMessage = loginError.reason;
                    });
                })
                .catch((error: any) => {
                    error.email = loginData.email;
                    eventStore.recordEvent('loginError', JSON.stringify(error));
                    console.log(error);
                    this.loginFailed = true;
                    this.loginErrorMessage = error.reason;
                });
        },

        logout() {
            simpleddp.call("logout").then(() => {
                Sentry.getCurrentScope().setUser(null);
                this.$reset();
            });

            simpleddp.subs.forEach((sub: any) => {
                sub.remove();
            });

        },

        sendResetEmail() {
            const eventStore = useEventsStore();
            simpleddp.call('getUserIdByEmail', this.email).then((resp: any) => {

                simpleddp.call('sendPasswordResetEmail', resp).then((spwresp: any) => {
                    eventStore.recordEvent('passwordReset', resp);
                    this.passwordResetMessage = spwresp[0].status;
                });
            });
        },

        userIsDeveloper() {
            // @ts-ignore
            return this.authDetails.email == 'adietz@cgshealth.com';
        },

        userHasModuleCategory(machineName: string) {
            // @ts-ignore
            const categories = Object.keys(this.modules).map(a => this.modules[a].category);
            return categories.includes(machineName);
        },

        userCategoryModule(categoryMachineName: string) {
            const modules = Object.keys(this.modules).map(a => { // @ts-ignore
                return {
                    module: a,
                    // @ts-ignore
                    category: this.modules[a].category
                };
            });
            //@ts-ignore
            const module = modules.find(a => a.category === categoryMachineName);

            if (!module) {
                return null;
            }
            return module.module;

        },

        userHasModule(machineName: string) {
            // @ts-ignore
            return Object.keys(this.modules).includes(machineName);
        },

        userHasOneOfModules(machineNames: string[]) {
            const filteredArray = Object.keys(this.modules).filter(value => machineNames.includes(value));
            return filteredArray.length > 0;
        },
        userHasMultipleModulesInCategory(categoryName: string
        ) {
            // @ts-ignore
            const modules = Object.keys(this.modules).map(a => this.modules[a]);
            const filteredArray = modules.filter(value => value.category === categoryName);
            return filteredArray.length > 1;
        },
        getModuleOptions(machineName: string) {
            // @ts-ignore
            return get(this.modules, `${machineName}.options`, {});
        },

        fetchUserAccessRequests() {
            axios.get('/member/accessrequests').then(({data}) => {
                this.pendingAccessRequests = data;
            });
        },

        //@ts-ignore
        requestAccess(requesteeMemberNumber) {
            // generate a new AccessRequest, and notify grantor
            const accessRequest = {
                userId: '',
                //@ts-ignore
                requestee: requesteeMemberNumber,
            }
            console.info('requesting access', accessRequest);
        },

        grantAccess(requestId: number) {
            axios.post(`/member/grantaccess/${requestId}`)
        },

        removeAccess(granteeId: string) {
            const removeAccessRequest = {
                grantorUserId: '',
                granteeUserId: '',
            }
            console.info('removing access from ', granteeId);
            axios.post(`/member/removeaccess`, removeAccessRequest);
        },


        inviteDependent(dependent: any, email: string) {
            dependent.primaryUserId = this.userId;
            dependent.dependentEmail = email;
            simpleddp.call('inviteDependent', dependent, email);
        },

        injectDemoData() {
            //console.log('injecting demo data');
            const demoData = [
                {
                    "network": "in",
                    "type": "deductible",
                    "scope": "individual",
                    "maxAmount": 1750,
                    "currentAmount": 1231,
                    "unit": "dollars"
                },
                {
                    "network": "in",
                    "type": "oop-max",
                    "scope": "individual",
                    "maxAmount": 7000,
                    "currentAmount": 5231,
                    "unit": "dollars"
                },
                {
                    "network": "in",
                    "type": "max-visits",
                    "scope": "individual",
                    "maxAmount": 12,
                    "currentAmount": 8,
                    "name": "Max Annual Physical Therapy Visits",
                    "unit": "count",
                },
            ];

            //@ts-ignore
            this.accumulators = demoData;
            //@ts-ignore
            this.activePlan.benefits = demoData;

        }
    },

    persist: {
        enabled: true,
        onRestored: (store) => {
            store.resume();
        }
    }
});
