<template>
  <ion-app>
    <div class="appContainer">

      <AppHeader></AppHeader>

      <div class="container" :class="{'dots': showDots, 'fullScreen': isFullScreen, 'withoutFooter':hideFooter}">
        <router-view></router-view>
      </div>

      <AppFooter v-if="!hideFooter"></AppFooter>

    </div>
  </ion-app>
</template>

<script setup>

import {IonApp} from '@ionic/vue';
import {get} from 'lodash-es';
import AppHeader from '@/components/header/AppHeader';
import AppFooter from '@/components/footer/AppFooter';
import {computed, ref, onMounted, onBeforeUnmount} from "vue";
import {useRoute} from 'vue-router';
import {Keyboard} from "@capacitor/keyboard";
import {Capacitor} from "@capacitor/core";


import {useNotificationStore} from "@/stores/notificationStore";
import {useConstantStore} from "@/stores/constantStore";
import {storeToRefs} from "pinia";

const notificationStore = useNotificationStore();
const constantStore = useConstantStore();

const {onboarding} = storeToRefs(constantStore);

onBeforeUnmount(() => {
  // if (safeAreaEventListener) {
  //   safeAreaEventListener.remove();
  // }
});

const route = useRoute();
const showDots = computed(() => {
  return get(route, 'meta.showDots', false);
});

const isFullScreen = computed(() => {
  const hideFooter = get(route, 'meta.disableFooter', false);
  const hideHeader = get(route, 'meta.disableHeader', false);
  return hideFooter && hideHeader;
});


const hideFooter = ref(false);
if (Capacitor.isNativePlatform()) {
  Keyboard.addListener('keyboardWillShow', () => {
    hideFooter.value = true;
  });

  Keyboard.addListener('keyboardWillHide', () => {
    hideFooter.value = false;
  });

  Keyboard.addListener('keyboardDidShow', () => {
    document.activeElement.scrollIntoView({behavior: "smooth"});
  });
}

onMounted(() => {
  if (onboarding.value.welcomed && onboarding.value.notificationPermissionPresented) {
    notificationStore.registerNotifications();
  }

});

</script>

<style lang="scss" scoped>


.appContainer {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;


  .container {
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;

  }
}


</style>
