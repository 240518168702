<template>
  <span v-bind="$attrs"
        :aria-hidden="!title"
        :aria-label="title"
        class="material-design-icon information-outline-icon"
        role="img"
        @click="$emit('click', $event)">
    <svg :fill="fillColor"
         class="material-design-icon__svg"
         :width="size"
         :height="size"
         viewBox="0 0 24 24">
      <path
          d="M22.6846 13.3155L17.3423 13.3155C16.9737 13.3155 16.6745 13.6155 16.6745 13.9832L16.6745 16.6543C16.6745 17.0228 16.9737 17.322 17.3423 17.322L22.6846 17.322C23.0532 17.322 23.3524 17.0228 23.3524 16.6543L23.3524 13.9832C23.3524 13.6155 23.0532 13.3155 22.6846 13.3155ZM17.3423 18.6578C16.2359 18.6578 15.339 17.761 15.339 16.6545L15.339 13.9835C15.339 12.8771 16.2358 11.9802 17.3423 11.9802L21.7497 11.9802C21.8971 11.9802 22.0169 11.8607 22.0169 11.713L22.0169 8.64107C22.0169 7.53465 21.1201 6.63776 20.0136 6.63776L0.914757 6.63748C0.767337 6.63748 0.647597 6.75695 0.647597 6.90464L0.647597 21.9967C0.647597 23.1031 1.54442 24 2.65091 24L20.0136 24C21.12 24 22.0169 23.1032 22.0169 21.9967L22.0169 18.9248C22.0169 18.7773 21.8975 18.6576 21.7498 18.6576L17.3423 18.6578ZM18.0101 2.00714C18.0101 1.32184 17.6821 0.711882 17.1094 0.335124C16.5362-0.0430082 15.8476-0.103839 15.2168 0.166337L3.83134 5.04566C3.6979 5.10293 3.73872 5.30186 3.88395 5.30186L17.7428 5.30186C17.8902 5.30186 18.01 5.18239 18.01 5.0347L18.0101 2.00714Z">
        <title v-if="title">{{ title }}</title>
      </path>

    </svg>
  </span>
</template>

<script>
export default {
  name: "WalletIcon",
  emits: ['click'],
  props: {
    title: {
      type: String,
    },
    fillColor: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: Number,
      default: 24
    }
  }
};
</script>
