import Pusher from 'pusher-js';
import Echo from 'laravel-echo';

import axios from 'axios';

const wsHost = 'ws.herculeshealth.app';
const key = 'PBQKgfiA0166x5kjJHEioC5rhHDhTROn';
window.pusher = Pusher;
window.echo = new Echo({
        broadcaster: 'pusher',
        wssHost: wsHost,
        wsHost: wsHost,
        wssPort: 443,
        wsPort: 80,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        key: key,
        cluster: 'us1',
        encrypted: true,
        forceTLS: true,

        authorizer: (channel) => {
            return {
                authorize: (socketId, callback) => {

                    axios.post('https://api.herculeshealth.app/api/broadcasting/auth', {
                        socket_id: socketId,
                        channel_name: channel.name
                    }, {
                        // headers: {'Authorization':`bearer: ${token.value}`}
                    }).then(response => {
                        callback(false, response.data);
                    }).catch(error => {
                        callback(true, error);
                    });
                }
            };
        },
    }
);





