import {defineStore} from 'pinia'

import axios from "axios";
import {useAuthStore} from "@/stores/authStore";
import {useNotificationStore} from "@/stores/notificationStore";
import {get} from "lodash-es";
import {PushNotificationSchema} from "@capacitor/push-notifications";
import {Capacitor} from "@capacitor/core";
import {Device} from "@capacitor/device";


// @ts-ignore
export const useIterableStore = defineStore({
    id: 'iterable',
    state: () => {
        return {
            iterableJwt: '',
            iterableJwtExpiry: 0,
            iterableDataReported: false,
            registeredDevices: [],
            iterableDeviceId: '',
            iterableUserRegistered: false,
        }
    },

    actions: {
        resume() {
            // console.log('iterable store restored');
        },

        getIterableSettings() {
            const authStore = useAuthStore();
            const out = {
                jwtUrl: `${authStore.getApiUrl()}optimity/jwt`,
                iterableApiUrl: 'https://api.iterable.com/api/',
                iterableApiKey: '427a47c4278641f2ab99cb6fac0c8ced',
                environment: 'production'
            };
            return out;
        },

        async getJwt() {
            const authStore = useAuthStore();

            if (!authStore.userHasOneOfModules(['optimity', 'optimityDevelopment'])) {
                return;
            }
            const iterableSettings = this.getIterableSettings();

            await axios.post(iterableSettings.jwtUrl, {
                userId: authStore.userId
            }).then(({data}) => {
                this.iterableJwt = data.token;
                this.iterableJwtExpiry = data.expiry;
            }).catch((e) => {
                console.log('unable to get iterable jwt', e);
            }).finally(() => {
                // do nothing
            });
        },

        async setIterableDataFieldProperties(properties: object) {
            const authStore = useAuthStore();

            if (!authStore.userHasOneOfModules(['optimity', 'optimityDevelopment'])) {
                return;
            }
            const iterableSettings = this.getIterableSettings();
            const propForm = {
                userId: get(authStore.authDetails, 'uuid', ''),
                dataFields: properties,
                mergeNestedObjects: true,
                email: null
            };
            const email = get(authStore, 'userData.profile.emailPrimary', false);
            if (email) {
                //@ts-ignore
                propForm.email = email;
            }
            if (!propForm.userId && !propForm.email) {
                return;
            }
            if (!this.iterableJwt) {
                await this.getJwt();
            }

            await axios.post(`${iterableSettings.iterableApiUrl}users/update`, propForm, {
                headers: {
                    "api-key": iterableSettings.iterableApiKey,
                    "Authorization": `Bearer ${this.iterableJwt}`
                }
            }).then(() => {
                //ne
            }).catch((e) => {
                console.log('unable to report iterable data', e);
            }).finally(() => {
                // do nothing
            });


        },

        async trackPushOpen(notification: PushNotificationSchema) {

            const authStore = useAuthStore();
            const notificationStore = useNotificationStore();
            if (!authStore.userHasOneOfModules(['optimity', 'optimityDevelopment'])) {
                return;
            }
            const iterableSettings = this.getIterableSettings();
            if (!notificationStore.iterableJwt) {
                await this.getJwt();
            }

            const iterableJSONString = get(notification, 'data.itbl', false);
            if (!iterableJSONString) {
                return;
            }
            let iterableData = {}
            if (typeof iterableJSONString === 'string') {
                iterableData = JSON.parse(iterableJSONString);
            } else {
                iterableData = iterableJSONString;
            }
            notification.data.itbl = iterableData;

            const propForm = {
                userId: get(authStore.authDetails, 'uuid', ''),
                campaignId: get(iterableData, 'campaignId', 0),
                templateId: get(iterableData, 'templateId', 0),
                messageId: get(iterableData, 'messageId', 0)
            }

            if (!propForm.userId) {
                return;
            }

            const email = get(authStore, 'userData.profile.emailPrimary', false);
            if (email) {
                //@ts-ignore
                propForm.email = email;
            }


            axios.post(`${iterableSettings.iterableApiUrl}events/trackPushOpen`, propForm, {
                headers: {
                    "api-key": iterableSettings.iterableApiKey,
                    "Authorization": `Bearer ${notificationStore.iterableJwt}`
                }
            }).then(() => {
                //ne
            }).catch((e) => {
                console.log('error in trackPushOpen', e);
            });

        },

        async setIterableDataFieldProperty(property: string, value: any) {
            const authStore = useAuthStore();
            const notificationStore = useNotificationStore();
            if (!authStore.userHasOneOfModules(['optimity', 'optimityDevelopment'])) {
                return;
            }
            const iterableSettings = this.getIterableSettings();
            const propForm = {
                userId: get(authStore.authDetails, 'uuid', ''),
                dataFields: {},
                mergeNestedObjects: true,
                email: null
            };
            const email = get(authStore, 'userData.profile.emailPrimary', false);
            if (email) {
                //@ts-ignore
                propForm.email = email;
            }
            if (!propForm.userId && !propForm.email) {
                return;
            }
            if (!notificationStore.iterableJwt) {
                await this.getJwt();
            }

            // @ts-ignore
            propForm.dataFields[property] = value;
            axios.post(`${iterableSettings.iterableApiUrl}users/update`, propForm, {
                headers: {
                    "api-key": iterableSettings.iterableApiKey,
                    "Authorization": `Bearer ${notificationStore.iterableJwt}`
                }
            }).then(() => {
                //ne
            }).catch((e) => {
                console.log('error in setIterableDataFieldProperty', e);
            }).finally(() => {
                // do nothing
            });

        },

        async logIterableEvent(eventSlug: string) {
            const iterableStore = useIterableStore();
            const authStore = useAuthStore();
            if (!authStore.userHasOneOfModules(['optimity', 'optimityDevelopment'])) {
                return;
            }

            const iterableSettings = this.getIterableSettings();
            const evtForm = {
                userId: get(authStore.authDetails, 'uuid', ''),
                eventName: eventSlug
            };
            const email = get(authStore, 'userDetails.profile.emailPrimary', false);

            if (email) {
                //@ts-ignore
                evtForm.email = email;
            }
            //@ts-ignore
            if (!evtForm.userId && !evtForm.email) {
                return;
            }
            if (!iterableStore.iterableJwt) {
                await iterableStore.getJwt();
            }


            axios.post(`${iterableSettings.iterableApiUrl}events/track`, evtForm, {
                headers: {
                    "api-key": iterableSettings.iterableApiKey,
                    "Authorization": `Bearer ${iterableStore.iterableJwt}`
                }
            }).then(() => {
                //ne
            }).catch((e) => {
                console.log('Error Tracking Iterable Event', e);

            }).finally(() => {
                // do nothing
            });
        },

        async fetchIterableMessages() {


            const authStore = useAuthStore();
            if (!authStore.userHasOneOfModules(['optimity', 'optimityDevelopment'])) {
                return;
            }
            const iterableStore = useIterableStore();
            const {
                iterableApiUrl,
                iterableApiKey
            } = iterableStore.getIterableSettings();
            //this is not an empty method
            axios.get(`${iterableApiUrl}inApp/getMessages`, {
                headers: {
                    "api-key": iterableApiKey,
                    "Authorization": `Bearer ${this.iterableJwt}`
                },
                params: {
                    userId: get(authStore.authDetails, 'uuid', ''),
                    count: 20
                }
            }).then(({data}) => {

                data.inAppMessages.forEach((msg: object) => {
                    //console.log('iterable message', msg);
                    // @ts-ignore
                    const exIdx = this.iterableMessages.findIndex(a => a.messageId == msg.messageId);

                    if (exIdx < 0) {
                        // @ts-ignore
                        this.iterableMessages.push(msg);
                    } else {
                        // @ts-ignore
                        this.iterableMessages.splice(exIdx, 1, msg);
                    }
                })
            }).catch(() => {
                // do nothing
            }).finally(() => {
                //still do nothing
            });
        },

        async resetIterableRegistration() {
            // @ts-ignore
            this.iterableUserRegistered = false;
            // @ts-ignore
            this.iterableJwt = '';
            this.iterableDeviceId = '';
            await this.registerUserIterable();
        },

        async registerUserIterable() {
            const authStore = useAuthStore();

            if (!authStore.userHasOneOfModules(['optimity', 'optimityDevelopment'])) {
                return;
            }
            await this.getJwt();

            if (this.iterableUserRegistered) {
                if (!this.iterableDeviceRegistered) {
                    await this.registerDeviceTokenIterable();
                }

                await this.fetchIterableMessages();
                return;
            }

            const registrationBody = {
                userId: get(authStore.authDetails, 'uuid', ''),
                email: get(authStore, 'userData.profile.emailPrimary', ''),
                dataFields: {
                    firstName: get(authStore, 'userData.profile.first_name', ''),
                },
                mergeNestedObjects: true,
            }

            if (!registrationBody.userId && !registrationBody.email) {
                return;
            }
            const {
                iterableApiUrl,
                iterableApiKey
            } = this.getIterableSettings();
            axios.post(`${iterableApiUrl}users/update`, registrationBody, {
                headers: {
                    "api-key": iterableApiKey,
                    "Authorization": `Bearer ${this.iterableJwt}`
                }
            }).then(() => {
                this.iterableUserRegistered = true;
                this.registerDeviceTokenIterable();
            }).catch(() => {
                //ne
            }).finally(() => {
                //ne
            });

        },

        async registerDeviceTokenIterable() {
            const authStore = useAuthStore();

            if (!authStore.userHasOneOfModules(['optimity', 'optimityDevelopment'])) {
                return;
            }
            const notificationStore = useNotificationStore();
            if (!Capacitor.isNativePlatform()) {
                //console.log('not native platform');
                return;
            }
            await Device.getId().then((d) => {
                this.iterableDeviceId = d.identifier;
            });


            if (!notificationStore.token) {
                await notificationStore.registerNotifications();

                if (!notificationStore.token) {
                    //console.log('could not get a notification token');
                    return;
                }

            }


            const deviceInfo = await Device.getInfo();
            const registrationBody = {
                userId: get(authStore.authDetails, 'uuid', ''),
                device: {
                    token: notificationStore.token,
                    platform: Capacitor.getPlatform() == 'ios' ? 'APNS' : 'GCM',
                    applicationName: "com.herculeshealth.mobile",
                    dataFields: {
                        deviceType: deviceInfo.model,
                        deviceName: deviceInfo.name,
                        deviceId: this.iterableDeviceId
                    }
                }
            }

            const {
                iterableApiUrl,
                iterableApiKey
            } = this.getIterableSettings();
            axios.post(`${iterableApiUrl}users/registerDeviceToken`, registrationBody, {
                headers: {
                    "api-key": iterableApiKey,
                    "Authorization": `Bearer ${this.iterableJwt}`
                }
            }).then(() => {
                this.fetchIterableDevices();
                // @ts-ignore
                this.iterablePushToken = this.token;
                //console.log('device registered', data);
            }).catch(() => {
                //ne
            });
        },

        async fetchIterableDevices() {
            const authStore = useAuthStore();
            if (!authStore.userHasOneOfModules(['optimity', 'optimityDevelopment'])) {
                return;
            }
            const {data} = await axios.get(`${authStore.getApiUrl()}member/appuser/iterable/devices?userId=${authStore.userId}`);
            this.registeredDevices = data;
        },

        async pruneIterableDevices() {
            await this.fetchIterableDevices();
            const {
                iterableApiUrl,
                iterableApiKey
            } = this.getIterableSettings();
            const authStore = useAuthStore();
            this.registeredDevices.forEach((d: any) => {
                axios.post(`${iterableApiUrl}users/disableDevice`, {
                    userId: get(authStore.authDetails, 'uuid', ''),
                    token: d.token
                }, {
                    headers: {
                        "api-key": iterableApiKey,
                        "Authorization": `Bearer ${this.iterableJwt}`
                    }
                }).catch((e) => {
                    console.log('error disabling device', e);
                });
            });

            this.registeredDevices = [];
        }

    },

    getters: {
        iterableDeviceRegistered: (state: any): boolean => {
            return state.registeredDevices.findIndex((a: any) => {
                return a.deviceId === state.iterableDeviceId && a.endpointEnabled;
            }) > -1;
        }
    },
    persist: {
        enabled: true,
        onRestored: (store) => {
            store.resume();
        }
    }
});
