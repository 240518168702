<template>
  <div class="overlay" v-if="pickerActive"></div>
  <transition name="datepicker">
    <div class="customDatePicker" v-if="pickerActive">
      <IonToolbar :color="color">
        <IonButtons slot="start">
          <IonButton @click="cancel">
            <CloseIcon></CloseIcon>
          </IonButton>
        </IonButtons>
        <IonButtons slot="end">
          <IonButton @click="save">
            <CheckCircleIcon></CheckCircleIcon>
          </IonButton>
        </IonButtons>
        <div class="title">{{ label }}</div>
      </IonToolbar>

      <IonDatetime
          :value="localValue"
          presentation="date"
          @ionChange="updateDateValue"
          prefer-wheel
          size="cover"
      ></IonDatetime>

    </div>
  </transition>
</template>

<script setup>
import {ref, defineProps} from 'vue';

import {IonButton, IonToolbar, IonDatetime, IonButtons} from "@ionic/vue";
import CloseIcon from 'vue-material-design-icons/Close';
import CheckCircleIcon from 'vue-material-design-icons/CheckCircle';
import {lightFormat, parseISO} from 'date-fns';


defineProps({
  modelValue: {},
  label: {},
  pickerActive: {
    type: Boolean
  },
  color: {
    default() {
      return 'primary';
    }
  }
});

const emit = defineEmits(['update:modelValue', 'cancel', 'change']);

const localValue = ref('');

function updateDateValue(evt) {
  localValue.value = evt.detail.value;
}

function cancel() {
  emit('cancel');
}

function save() {
  const isoDate = parseISO(localValue.value);
  const formattedDateString = lightFormat(isoDate, 'yyyy-MM-dd');

  emit('update:modelValue', formattedDateString);
  emit('change', formattedDateString);
}
</script>

<style scoped lang=scss>

.title {
  font-size: 1.1em;
  line-height: 1.3em;
}

.customDatePicker {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  background-color: var(--ion-color-card-background);
  padding: 0 0 6rem 0;
  box-shadow: 0 0 10px;
}

.overlay {
  background-color: rgba(0, 0, 0, .3);
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
}


.datepicker-enter-active {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}


</style>
