// Pinia authStore
// noinspection JSVoidFunctionReturnValueUsed

import {defineStore} from 'pinia'
// @ts-ignore
export const useConstantStore = defineStore({
    id: 'constant',
    state: () => {
        return {
            onboarding: {
                welcomed: false,
                locationPermissionPresented: false,
                healthPermissionPresented: false,
                notificationPermissionPresented: false,
                shoppingDisclosureAcknowledged: false
            }
        };
    },
    actions: {
        acknowledgeShoppingDisclosure() {
            this.onboarding.shoppingDisclosureAcknowledged = true;
        }
    },

    getters: {},

    persist: {
        enabled: true,
    }
});
