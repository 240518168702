<template>
  <ion-app>

    <div class="appContainer">


      <app-header></app-header>
      <div class="container" :class="{'dots': showDots, 'fullScreen': isFullScreen}">
        <router-view></router-view>
      </div>


    </div>
  </ion-app>
</template>

<script setup>

import {IonApp} from '@ionic/vue';
import {get} from 'lodash-es';
import AppHeader from '@/components/header/AppHeader';

import {computed, watch} from "vue";
import {useRoute, useRouter} from 'vue-router';
import {useIdle} from '@vueuse/core';

const router = useRouter();
const idleOptions = {
  events: ['mousedown', 'resize', 'keydown', 'touchstart', 'wheel']
};
const {idle} = useIdle(60 * 60 * 1000, idleOptions);

const route = useRoute();
const showDots = computed(() => {
  return get(route, 'meta.showDots', false);
});

const isFullScreen = computed(() => {
  const hideFooter = get(route, 'meta.disableFooter', false);
  const hideHeader = get(route, 'meta.disableHeader', false);
  return hideFooter && hideHeader;
});

watch(idle, (newValue) => {

  if (newValue) {
    router.push('/logout');
  }
});


</script>

<style lang="scss" scoped>

.headerbg {
  position: absolute;
  left: 0;
  top: 0;
  height: 200px;
  width: 100%;
  background-color: var(--ion-color-secondary);
  z-index: 0;
}

.appContainer {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  max-width: 840px;
  display: flex;
  flex-direction: column;


  .container {
    padding-left: 1rem;
    padding-right: 1rem;
    flex-grow: 1;
    overflow: hidden;
    width: 100%;


    &.fullScreen {
      height: 100vh;
      top: 0px;
      padding-bottom: 0;
      background-color: var(--ion-color-primary);
    }

  }
}


</style>
