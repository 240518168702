<template>
  <div
      class="desktopMenuItem"
      :class="{'activeItem': isActive}"
      @click="handleClick"
  >
    <slot></slot>
  </div>

</template>

<script setup>

import {computed, defineProps} from "vue";
import {useRoute, useRouter} from 'vue-router';
import {Haptics, ImpactStyle} from "@capacitor/haptics";
import {Capacitor} from "@capacitor/core";

const props = defineProps(['title', 'route']);
const currentRoute = useRoute();
const router = useRouter();

const isActive = computed(() => {
  return currentRoute.path === props.route;
});

function handleClick() {
  if (Capacitor.isNativePlatform()) {
    Haptics.impact({style: ImpactStyle.Medium});
  }
  router.push(props.route);
}


</script>

<style scoped lang="scss">
.desktopMenuItem {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  text-align: center;
  margin-right: 2rem;
  margin-top: -10px;
  cursor: pointer;
}

.activeItem {
  border-top: 5px solid #00e69f;
  padding-top: 15px;
}


</style>
