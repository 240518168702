<template>
  <div class="menuIcon">
    <MenuIcon
        fill-color="white"
        @click="showMenu"
    ></MenuIcon>

    <transition name="tilt-in-tr">
      <template v-if="state.menuVisible">

        <div class="rightDrawer" ref="menu">
          <div class="content">
            <div @click="hideMenu" class="closeMenuDiv">{{ $t('menu.close') }}
              <CloseIcon :size="12"></CloseIcon>
            </div>

            <div class="menuItems">


              <AppHeaderMenuItem :text="$t('menu.home')" to="/" @route="hideMenu">
                <template v-slot:icon>
                  <HomeIcon></HomeIcon>
                </template>
              </AppHeaderMenuItem>


              <AppHeaderMenuItem :text="$t('menu.settings')" to="/settings" @route="hideMenu">
                <template v-slot:icon>
                  <CogIcon></CogIcon>
                </template>
              </AppHeaderMenuItem>

              <AppHeaderMenuItem
                  :text="$t('menu.privacy')"
                  to="/privacy"
                  @route="hideMenu"
              >
                <template v-slot:icon>
                  <IncognitoIcon></IncognitoIcon>
                </template>
              </AppHeaderMenuItem>


              <AppHeaderMenuItem :text="$t('menu.support')" to="/support" @route="hideMenu">
                <template v-slot:icon>
                  <ChatQuestionIcon></ChatQuestionIcon>
                </template>
              </AppHeaderMenuItem>

              <AppHeaderMenuItem :text="$t('demographics.myDetails')" to="/demographics" @route="hideMenu">
                <template v-slot:icon>
                  <AccountDetailsIcon></AccountDetailsIcon>
                </template>
              </AppHeaderMenuItem>

              <AppHeaderMenuItem :text="$t('menu.dependents')" to="/dependents" @route="hideMenu">
                <template v-slot:icon>
                  <AccountMultipleIcon></AccountMultipleIcon>

                </template>
              </AppHeaderMenuItem>

              <AppHeaderMenuItem :text="$t('menu.notifications')" to="/notifications" @route="hideMenu">
                <template v-slot:icon>
                  <MessageIcon></MessageIcon>
                </template>
              </AppHeaderMenuItem>

              <AppHeaderMenuItem :text="$t('menu.notices')" to="/notices" @route="hideMenu">
                <template v-slot:icon>
                  <ClipboardTextIcon></ClipboardTextIcon>
                </template>
              </AppHeaderMenuItem>


              <div class="spacer"></div>

              <AppHeaderMenuItem :text="$t('menu.logout')" to="/logout" @route="hideMenu">
                <template v-slot:icon>
                  <ExitRunIcon></ExitRunIcon>
                </template>
              </AppHeaderMenuItem>


            </div>

            <img src="/assets/images/herclogo.svg"
                 alt="Hercules Health"
                 width="200px"
                 class="logo"
                 @dblclick="onLogoDoubleClick"
            >
            <div class="version" v-if="env.NODE_ENV != 'development'">{{ version }}</div>
            <div class="version" v-else>{{ env.NODE_ENV }}</div>
          </div>
        </div>

      </template>
    </transition>
  </div>

</template>

<script setup>

import AppHeaderMenuItem from './AppHeaderMenuItem';
import MenuIcon from "vue-material-design-icons/Menu.vue";
import CogIcon from 'vue-material-design-icons/Cog.vue';
import ChatQuestionIcon from 'vue-material-design-icons/ChatQuestion.vue';
import AccountMultipleIcon from 'vue-material-design-icons/AccountMultiple.vue';
import ExitRunIcon from 'vue-material-design-icons/ExitRun.vue';
import HomeIcon from 'vue-material-design-icons/Home.vue';
import CloseIcon from 'vue-material-design-icons/Close.vue';
import MessageIcon from 'vue-material-design-icons/Message.vue';
import ClipboardTextIcon from 'vue-material-design-icons/ClipboardText.vue';
import IncognitoIcon from 'vue-material-design-icons/IncognitoCircle.vue';
import AccountDetailsIcon from 'vue-material-design-icons/AccountDetails.vue';
import {reactive, ref} from 'vue';

import {onClickOutside} from '@vueuse/core';
import version from '@/version';
import {Haptics, ImpactStyle} from "@capacitor/haptics";
import {CapacitorUpdater} from "@capgo/capacitor-updater";
import {Capacitor} from "@capacitor/core";


const state = reactive({menuVisible: false});
const env = ref(process.env);
const menu = ref(null);

function showMenu() {
  if (Capacitor.isNativePlatform()) {
    Haptics.impact({style: ImpactStyle.Heavy});
  }
  state.menuVisible = true;
}

function hideMenu() {
  if (Capacitor.isNativePlatform()) {
    Haptics.impact({style: ImpactStyle.Light});
  }
  state.menuVisible = false;
}


onClickOutside(menu, hideMenu);

async function onLogoDoubleClick() {
  CapacitorUpdater.getLatest().then((resp) => {
    if (resp.version != '' && version != resp.version) {
      CapacitorUpdater.download({
        url: resp.url
      }).then((newVersion) => {
        CapacitorUpdater.set(newVersion);
      });

    }
  });
}

</script>

<style scoped lang="scss">

.menuItems {

  flex-grow: 1;

  display: flex;
  flex-direction: column;

  margin-bottom: 2rem;

  .spacer {
    flex-grow: 1;
  }
}


.menuIcon {
  width: 34px;
  height: 34px;
  margin-right: .5rem;
}

.closeMenuDiv {
  margin-bottom: 2rem;
  cursor: pointer;
}

.rightDrawer {
  position: fixed;
  top: 0;
  right: 0;
  background-color: var(--ion-color-secondary);
  height: 100vh;
  z-index: 10000;
  padding-top: var(--safe-area-top, 0px);
  padding-bottom: var(--safe-area-bottom, 0px);
  -webkit-box-shadow: -19px 0px 21px -17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -19px 0px 21px -17px rgba(0, 0, 0, 0.75);
  box-shadow: -19px 0px 21px -17px rgba(0, 0, 0, 0.75);

  .content {
    position: relative;
    color: white;
    height: 100%;
    padding: 2rem;
    text-align: right;
    display: flex;
    flex-direction: column;


  }

}


.logo {

  width: 200px;
}

.version {
  color: white;
  margin-top: -15px;
  width: 200px;
  font-size: 10px;

}


.menu-enter-active {
  -webkit-animation: slide-in-right 0.3s both;
  animation: slide-in-right 0.3s both;

}


.menu-leave-active {
  -webkit-animation: slide-out-blurred-right 0.25s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
  animation: slide-out-blurred-right 0.25s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}


/**
 * ----------------------------------------
 * animation slide-out-blurred-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-blurred-right {
  0% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;

  }
  100% {
    -webkit-transform: translateX(1000px) scaleX(2) scaleY(0.2);
    transform: translateX(1000px) scaleX(2) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;

  }
}

@keyframes slide-out-blurred-right {
  0% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 100% 100%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px) scaleX(2) scaleY(0.2);
    transform: translateX(1000px) scaleX(2) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
}


/**
 * ----------------------------------------
 * animation tilt-in-tr
 * ----------------------------------------
 */

.tilt-in-tr-enter-active {
  //-webkit-animation: tilt-in-tr 0.65s cubic-bezier(0.450, 0.460, 0.450, 0.940) both;
  animation: tilt-in-tr 0.35s cubic-bezier(0.45, 0.46, 0.450, 0.940) both;

}

//@-webkit-keyframes tilt-in-tr {
//  0% {
//    -webkit-transform: rotateY(-35deg) rotateX(20deg) translate(250px, -250px) skew(-12deg, -15deg);
//    transform: rotateY(-35deg) rotateX(20deg) translate(250px, -250px) skew(-12deg, -15deg);
//    opacity: 0;
//  }
//  100% {
//    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
//    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
//    opacity: 1;
//  }
//}

@keyframes tilt-in-tr {
  0% {
    //-webkit-transform: rotateY(-35deg) rotateX(20deg) translateX(250px) translateY(-250px) translateZ(1000px) skew(-12deg, -15deg);
    transform: rotateY(35deg) rotateX(-20deg) translateX(100%) translateY(-100%) translateZ(1000px) skew(12deg, -15deg);
    opacity: 1;

  }
  100% {
    //-webkit-transform: rotateY(0) rotateX(0deg) translateX(0) translateY(0) translateZ(0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translateX(0) translateY(0) translateZ(0) skew(0deg, 0deg);
    opacity: 1;

  }
}


</style>
