// Pinia optimityStore
// noinspection JSVoidFunctionReturnValueUsed

import {defineStore} from 'pinia'

import axios from "axios";

// @ts-ignore
import {v4 as uuidv4} from 'uuid';
import {useAuthStore} from "@/stores/authStore";

// @ts-ignore
export const useEventsStore = defineStore({
    id: 'events',

    state: () => {
        return {
            events: [],
            reportingEvents: false,
            lastEventPush: '',
        };
    },

    actions: {

        recordEvent(eventType: string, eventNote: string) {
            const authStore = useAuthStore();
            const eventIdentifier = uuidv4();
            const eventRecord = {
                eventIdentifier,
                eventType,
                eventTime: (new Date).toISOString(),
                eventUserId: authStore.userId,
                eventNote
            };

            //@ts-ignore
            this.events.push(eventRecord);
            this.reportEvent(eventIdentifier).then(() => {
                //do nothing
            });

        },

        async reportEvent(eventIdentifier: string) {
            const authStore = useAuthStore();
            const eventToReport = this.events.find((event: any) => {
                return event.eventIdentifier === eventIdentifier;
            });
            const formData = {
                user_uuid: authStore.userId,
                events: [eventToReport]
            }
            await axios.post(`${authStore.getApiUrl()}member/events`, formData).then(() => {
                const index = this.events.findIndex((event: any) => {
                    return event.eventIdentifier === eventIdentifier;
                });
                this.events.splice(index, 1);

                this.reportingEvents = false;
            }).catch(() => {

                this.reportingEvents = false;
            });
        },

    },
    persist: {
        enabled: true
    },
});
