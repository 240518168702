// @ts-ignore
import simpleDDP from 'simpleddp';
// @ts-ignore
import {simpleDDPLogin} from 'simpleddp-plugin-login';
import ws from 'isomorphic-ws';

const opts = {
    endpoint: "wss://mymedicalshopper.com/websocket",
    SocketConstructor: ws,
    reconnectInterval: 10000
};

const ddpServer = new simpleDDP(opts, [simpleDDPLogin]);


ddpServer.on('error', (e: any) => {
    console.log(`DDP ERROR: ${JSON.stringify(e)}`);
});

ddpServer.on('disconnected', () => {
    console.log('DDP DISCONNECTED');

});

export default ddpServer;
