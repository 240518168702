import {createApp} from 'vue';

import MainApp from './MainApp.vue';

import {SplashScreen} from '@capacitor/splash-screen';
import {IonicVue} from '@ionic/vue';

import pinia from '@/stores/pinia';

import router from './router';
import axios from "axios";


import {Capacitor} from '@capacitor/core';
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {PushNotifications} from "@capacitor/push-notifications";
import {CapacitorUpdater} from "@capgo/capacitor-updater";
import {useAuthStore} from '@/stores/authStore';
import {Vue3Mq} from "vue3-mq";
import VueGoogleMaps from 'vue-google-maps-community-fork';
import {defineCustomElements} from '@ionic/pwa-elements/loader';
import * as Sentry from "@sentry/vue";

import Vue3TouchEvents from "vue3-touch-events";
import {createI18n} from 'vue-i18n';

import './plugins/objectid';
import './plugins/echo';

// @ts-ignore
import {defaultLocale, languages} from '@/translations'
import '@ionic/vue/css/core.css';
import './theme/variables.css';

import 'chartist/dist/chartist.min.css';

import 'crypto';

import {Directory, Filesystem} from "@capacitor/filesystem";

// @ts-ignore
import version from './version';

import {SafeArea} from "capacitor-plugin-safe-area";
import {useIterableStore} from "@/stores/iterableStore";
import {get} from "lodash-es";
import {Preferences} from "@capacitor/preferences";
import {Device} from "@capacitor/device";

SplashScreen.show({});
defineCustomElements(window).then(() => {
    //do nothing
});

if (Capacitor.isNativePlatform()) {
    CapacitorUpdater.notifyAppReady();

    Filesystem.readdir(
        {
            directory: Directory.Cache,
            path: 'Hercules'
        }
    ).then(() => {
        //
    }).catch(() => {
        Filesystem.mkdir({
            path: 'Hercules',
            directory: Directory.Cache
        });
    });

    App.addListener('appStateChange', (appState: {
        isActive: any;
    }) => {
        const store = useAuthStore(pinia);

        if (!appState.isActive) {
            if (store.locked) {
                return;
            }

            store.inactiveAt = Date.now();
        } else {
            if (store.inactiveAt != 0) {
                const now = Date.now();
                const timeInactive = now - store.inactiveAt;

                if (timeInactive > 60000) {
                    store.locked = true;
                    store.lockedRoute = router.currentRoute.value.fullPath;
                    router.push('/unlock').then(() => {
                        //not empty
                    });
                }
            }

        }
    });


}


axios.defaults.baseURL = 'https://api.herculeshealth.app/api/member';

if (process.env.NODE_ENV === "development") {

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    // axios.defaults.baseURL = 'http://herculeshealth-app.test/api/member';
    // axios.defaults.baseURL = 'https://preprod.herculeshealth.app/api/member';
    // console.info('running in development mode.  using preprod api');
}

const messages = Object.assign(languages);
let locale = {value: defaultLocale};

const localePref = await Preferences.get({key: 'language'});

if (!localePref.value) {
    locale = await Device.getLanguageCode();
}

const i18n = createI18n({
    legacy: false,
    locale: locale.value,
    fallbackLocale: defaultLocale,
    messages
});


// @ts-ignore
const vueapp = createApp(MainApp)

    .use(IonicVue)
    .use(pinia)
    .use(router)
    .use(i18n)
    .use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyAGehaIK9a16CZ4mGor46sNby6XquekGHY'
        }
    })
    .use(Vue3TouchEvents)
    .use(Vue3Mq);


Sentry.init({
    app: vueapp,
    dsn: "https://d6a0095a06024b89a94d72e87d23557d@o1290055.ingest.sentry.io/6509807",

    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 10,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: "hercules-mobile@" + version,
    autoSessionTracking: true

});


router.isReady().then(async () => {
    const authStore = useAuthStore(pinia);
    const constantStore = useAuthStore(pinia);
    //@ts-ignore
    await authStore.restored;
    //@ts-ignore
    await constantStore.restored;
    vueapp.mount('#app');
    if (authStore.locked) {
        router.push('/unlock').then(() => {/*not empty*/
        });
    }


    if (authStore.isAuthenticated) {
        authStore.fetchUserModules();
    }

    CapacitorUpdater.addListener('appReady', () => {
        // Hide splash
        SplashScreen.hide();
        // @ts-ignore
        if (window['intendedRoute']) {
            // @ts-ignore
            authStore.intendedRoute = window['intendedRoute'];
            // @ts-ignore
            window['intendedRoute'] = '';
            return;
        }

        if (authStore.intendedRoute) {

            const url = authStore.intendedRoute;
            authStore.intendedRoute = '';
            router.push(url).then(() => {/*not empty*/
            });
        }
    });


});

// @ts-ignore

App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
    const authStore = useAuthStore(pinia);
    //@ts-ignore
    await authStore.restored;
    const slug = event.url.split('.app').pop();

    if (slug && slug.indexOf('enroll-account') > -1) {
        
        router.push({path: slug.replace('#', '')}).then(() => {
            //not empty
        });

    }


    if (slug) {
        // @ts-ignore
        window["intendedRoute"] = slug;
        authStore.intendedRoute = slug;
        if (authStore.isAuthenticated) {
            router.push({
                path: slug,
            }).then(() => {
                //not empty
            });
        }

    }
});

if (Capacitor.isNativePlatform()) {

    PushNotifications.addListener('pushNotificationActionPerformed', async ({notification}) => {
        console.info('push notification action performed', notification);

        const authStore = useAuthStore(pinia);
        // @ts-ignore
        await authStore.restored;
        const iterableData = get(notification, 'data.itbl', {});

        let iterableJson = {};
        if (typeof iterableData === 'string') {
            iterableJson = JSON.parse(iterableData);
            // @ts-ignore
            iterableJson.defaultAction.data = decodeURI(iterableJson.defaultAction.data);
        } else {
            iterableJson = iterableData;
        }


        const actionType = get(iterableJson, 'defaultAction.type', "");


        // @ts-ignore
        if (actionType === 'openUrl') {
            const url = get(iterableJson, 'defaultAction.data', false);
            if (url) {

                // @ts-ignore
                const slug = url.split('.app').pop();
                const iterableStore = useIterableStore(pinia);
                await iterableStore.trackPushOpen(notification);

                if (slug) {
                    // @ts-ignore
                    window['intendedRoute'] = slug;
                    authStore.intendedRoute = slug;
                    if (authStore.isAuthenticated) {
                        await router.push({
                            path: slug,
                        });
                    }

                }
            }

        } else {
            //console.log('default action type is not openUrl', get(iterableJson, 'defaultAction.type', ""));
        }


    });


    await SafeArea.getSafeAreaInsets().then(({insets}) => {

        for (const [key, value] of Object.entries(insets)) {
            document.documentElement.style.setProperty(
                `--safe-area-${key}`,
                `${value}px`,
            );
        }
    });

    SafeArea.addListener('safeAreaChanged', data => {
        const {insets} = data;

        for (const [key, value] of Object.entries(insets)) {
            document.documentElement.style.setProperty(
                `--safe-area-${key}`,
                `${value}px`,
            );
        }
    });


}


