<template>
  <div class="appFooter medium-shadow" v-if="!disableFooter">
    <app-footer-item
        route="/"
    >
      <home-icon></home-icon>
    </app-footer-item>

    <app-footer-item
        v-if="authStore.userHasModule('procedureShopping')"
        route="/shopping"
    >
      <magnify-icon></magnify-icon>
    </app-footer-item>

    <app-footer-item
        route="/wallet"
        v-if="authStore.userHasModule('wallet')"
    >
      <WalletIcon :size="20"></WalletIcon>
    </app-footer-item>

    <app-footer-item
        route="/profile"
    >
      <account-icon></account-icon>
    </app-footer-item>
  </div>

</template>

<script setup>

import AppFooterItem from './AppFooterItem';
import AccountIcon from 'vue-material-design-icons/Account.vue';
import WalletIcon from '@/components/icons/WalletIcon.vue';
import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
import HomeIcon from 'vue-material-design-icons/Home.vue';
import {computed} from "vue";
import {get} from "lodash-es";
import {useRoute} from "vue-router";
import {useAuthStore} from "@/stores/authStore";

const route = useRoute();
const disableFooter = computed(() => {
  return get(route, 'meta.disableFooter', false);
});

const authStore = useAuthStore();


</script>

<style scoped lang="scss">
.appFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: calc(10px + var(--safe-area-bottom, 0px));
  width: 100%;
  background-color: white;
  z-index: 3;


  // background-color: var(--ion-color-card-background);
}
</style>
