<template>
  <div class="appHeader" v-if="!disableHeader" :class="{isAndroid:isAndroid}">
    <div class="backButton" v-if="showBackButton" @click="handleBackButton">
      <arrow-left-thick-icon fill-color="white"></arrow-left-thick-icon>
    </div>

    <div class="backButtonAlternate" v-else></div>
    <div class="imageWrapper" v-if="showLogo" @click="handleLogoClick">
      <img src="/assets/images/herclogo.svg" alt="Hercules Health">
    </div>
    <div class="spacer"></div>
    <MqResponsive target="md+">
      <DesktopMenu></DesktopMenu>
    </MqResponsive>

    <div class="chatWrapper">
      <matrix-chat></matrix-chat>
    </div>

    <div class="menuWrapper">
      <app-header-menu></app-header-menu>
    </div>
  </div>
</template>

<script setup>

import AppHeaderMenu from './AppHeaderMenu';
import DesktopMenu from '@/components/DesktopMenu.vue';
import ArrowLeftThickIcon from 'vue-material-design-icons/ArrowLeftThick.vue';
import {get} from 'lodash-es';
import {computed, onMounted} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {MqResponsive} from "vue3-mq";
import {Capacitor} from "@capacitor/core";
import {SafeArea} from "capacitor-plugin-safe-area";
import {StatusBar} from "@capacitor/status-bar";
import MatrixChat from "@/components/chat/MatrixChat.vue";

const route = useRoute();
const router = useRouter();

const isAndroid = computed(() => {

  return Capacitor.getPlatform() === 'android';
});
const showBackButton = computed(() => {
  return get(route, 'meta.showBack', false);
});

const isNative = Capacitor.isNativePlatform();

onMounted(() => {
  setTimeout(async () => {
    if (isNative) {
      await SafeArea.setImmersiveNavigationBar();
      if (isAndroid.value) {
        await StatusBar.setOverlaysWebView({overlay: true});
      }
    }
  }, 100);


});

function handleBackButton() {
  const gotoUrl = get(route, 'meta.backLocation', false);
  if (!gotoUrl) {
    router.goBackOrHome();
    return;
  }

  router.replace(gotoUrl);
}

function handleLogoClick() {
  router.replace('/');
}

const showLogo = computed(() => {
  return get(route, 'meta.showLogo', false) || !isNative;
});

const disableHeader = computed(() => {
  return get(route, 'meta.disableHeader', false);
});


</script>

<style scoped lang="scss">

.appHeader {
  background-color: var(--ion-color-primary);
  color: white;
  padding-top: calc(10px + var(--safe-area-top, 30px));
  width: 100%;
  display: flex;
  justify-content: space-between;

  .spacer {
    flex-grow: 1;
  }

  &.isAndroid {
    padding-top: 40px !important;
  }
}


.menuWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.imageWrapper {
  height: 35px;
  width: 40vw;
  margin-left: 1rem;
  margin-bottom: 10px;
  flex-grow: 1;

  img {
    height: 100%;
  }

}

.backButton {
  width: 40px;
  height: 40px;
  margin-left: .5rem;
}

.backButtonAlternate {
  flex-shrink: 1;
  width: 0;
}


</style>
