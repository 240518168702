import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';


const Login = () => import(/*webpackChunkName: 'auth' */ '@/views/LoginView.vue');
const Logout = () => import(/*webpackChunkName: 'auth' */ '@/views/LogoutView.vue');
const Unlock = () => import(/*webpackChunkName: 'auth' */ '@/views/UnlockView.vue');
const Lock = () => import(/*webpackChunkName: 'auth' */ '@/views/LockView.vue');
const Signup = () => import(/*webpackChunkName: 'auth' */ '@/views/SignupView.vue');
const FirstLaunchView = () => import(/*webpackChunkName: 'auth' */ '@/views/FirstLaunchView.vue');
const RequestPasswordResetView = () => import(/*webpackChunkName: 'auth' */ '@/views/RequestPasswordResetView.vue');

const EOBView = () => import(/*webpackChunkName: 'claims' */ '@/views/EOBView.vue');
const PasswordResetView = () => import(/*webpackChunkName: 'auth' */ '@/views/PasswordReset.vue');
const ActivateAccountView = () => import(/*webpackChunkName: 'auth' */ '@/views/ActivateAccountView.vue');

const MSAView = () => import(/*webpackChunkName: 'msa' */ '@/views/MSAView.vue');

const Profile = () => import(/*webpackChunkName: 'profile' */ '@/views/ProfileView.vue');
const Dependents = () => import(/*webpackChunkName: 'profile' */ '@/views/DependentsView.vue');
const IDCard = () => import(/*webpackChunkName: 'profile' */ '@/views/IDCardView.vue');
const MedicalHistory = () => import(/*webpackChunkName: 'profile' */ '@/views/MedicalHistoryView.vue');
const PlanDetails = () => import(/*webpackChunkName: 'profile' */ '@/views/PlanDetails.vue');


const Claims = () => import(/*webpackChunkName: 'claims' */ '@/views/ClaimsView.vue');
const SingleClaim = () => import(/*webpackChunkName: 'claims' */ '@/views/SingleClaimView.vue');
const RxClaim = () => import(/*webpackChunkName: 'claims' */ '@/views/RxClaimView.vue');

const Support = () => import(/*webpackChunkName: 'support' */ '@/views/SupportView.vue');
const NoAuthSupport = () => import(/*webpackChunkName: 'support' */ '@/views/NoAuthSupportView.vue');
const ActiveTicket = () => import(/*webpackChunkName: 'support' */ '@/views/ActiveTicketView.vue');


const Accounts = () => import(/*webpackChunkName: 'accounts' */ '@/views/AccountsView.vue');

const Telemedicine = () => import(/*webpackChunkName: 'telemedicine' */ '@/views/TelemedicineView.vue');


// const Rewards = () => import(/*webpackChunkName: 'rewards' */ '@/views/WalletView.vue');

const ManageAvatarView = () => import(/*webpackChunkName: 'avatar' */ '@/views/ManageAvatarView.vue');
const AvatarUploadView = () => import(/*webpackChunkName: 'avatar' */ '@/views/AvatarUploadView.vue');

const RightwayView = () => import(/*webpackChunkName: 'rightway' */ '@/views/RightwayView.vue');
const RightwayChatView = () => import(/*webpackChunkName: 'rightway' */ '@/views/RightwayChatView.vue');
const RightwayBillSupportView = () => import(/*webpackChunkName: 'rightway' */ '@/views/RightwayBillSupportView.vue');
const RightwaySubmitBillView = () => import(/*webpackChunkName: 'rightway' */ '@/views/RightwaySubmitBillView.vue');

const NotificationSettings = () => import(/*webpackChunkName: 'settings' */ '@/views/NotificationSettings.vue');
const Settings = () => import(/*webpackChunkName: 'settings' */ '@/views/SettingsView.vue');


const ProcedurePrices = () => import(/*webpackChunkName: 'shopping' */ '@/views/ProcedurePrices.vue');
const Facility = () => import(/*webpackChunkName: 'shopping' */ '@/views/FacilityView.vue');
const Shopping = () => import(/*webpackChunkName: 'shopping' */ '@/views/ShoppingView.vue');

const OptimitySurveyView = () => import(/*webpackChunkName: 'optimity'*/ '@/views/OptimitySurveyView.vue');
const OptimitySurveyListView = () => import(/*webpackChunkName: 'optimity'*/ '@/views/OptimitySurveyListView.vue');
const OptimityRewardsRedemptionView = () => import(/*webpackChunkName: 'optimity'*/ '@/views/OptimityRewardsRedemption.vue');
const OptimityChallengeView = () => import(/*webpackChunkName: 'optimity'*/ '@/views/OptimityChallengeView.vue');

const OptimityEarnRewardsView = () => import(/*webpackChunkName:'optimity'*/ '@/views/OptimityEarnRewardsView.vue');
const IterableInboxMessageView = () => import(/*webpackChunkName: 'optimity'*/ '@/views/IterableInboxMessageView.vue');

const WalletView = () => import(/*webpackChunkName:'wallet'*/ '@/views/WalletView.vue');

const RxServicesView = () => import(/*webpackChunkName: 'rx'*/ '@/views/RxServicesView.vue');

const PrivacyView = () => import(/*webpackChunkName: 'settings'*/ '@/views/PrivacyView.vue');
const DemographicsView = () => import(/*webpackChunkName: 'settings'*/ '@/views/DemographicsView.vue');

import pinia from '@/stores/pinia';
import {useAuthStore} from '@/stores/authStore';
import {get} from 'lodash-es';


const routes: Array<RouteRecordRaw> = [
    {
        path: '/ssotest',
        name: 'ssotest',
        component: () => import(/*webpackChunkName: 'testing' */ '@/views/SSOTestView.vue'),
        meta: {
            auth: true,
            showLogo: false,
            showDots: false,
            showBack: true
        }
    },


    {
        path: '/',
        name: 'home',
        component: () => import(/*webpackChunkName: 'profile' */ '@/views/HomeView.vue'),
        meta: {
            auth: true,
            showLogo: true,
            showDots: true,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            disableHeader: true,
            disableFooter: true,
            showDots: true,
        }
    },
    {
        path: '/requestpasswordreset',
        name: 'RequestPasswordResetView',
        component: RequestPasswordResetView,
        meta: {
            disableHeader: true,
            disableFooter: true,
            showDots: true,
        }
    },
    {
        path: '/reset-password/:resetkey',
        name: 'PasswordResetView',
        component: PasswordResetView,
        meta: {
            disableHeader: true,
            disableFooter: true,
            showDots: true,
        },
        props: true
    },

    {
        path: '/enroll-account/:enrollKey',
        name: 'ActivateAccountView',
        component: ActivateAccountView,
        meta: {
            disableHeader: true,
            disableFooter: true,
            showDots: true,
        },
        props: true
    },

    {
        path: '/firstlaunch',
        name: 'firstlaunch',
        component: FirstLaunchView,
        meta: {
            disableHeader: true,
            disableFooter: true,
            showDots: true,
        }
    },

    {
        path: '/unlock',
        name: 'unlock',
        component: Unlock,
        meta: {
            disableHeader: true,
            disableFooter: true,
            showDots: true,
        }
    },
    {
        path: '/lock',
        name: 'lock',
        component: Lock,
        meta: {
            disableHeader: true,
            disableFooter: true,
            showDots: true,
        }
    },

    {
        path: '/logout',
        name: 'logout',
        component: Logout,
    },

    {
        path: '/signup',
        name: 'signup',
        component: Signup,
        meta: {
            disableHeader: true,
            disableFooter: true,
            showDots: true,
        }
    },
    {
        path: '/shopping',
        name: 'shopping',
        component: Shopping,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        }
    },
    {
        path: '/procedure/:procedureSlug',
        name: 'procedureprices',
        component: ProcedurePrices,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        },
        props: true
    },
    {
        path: '/facility/:facilitySlug',
        name: 'facility',
        component: Facility,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        },
        props: true
    },
    {
        path: '/wallet',
        name: 'wallet',
        component: WalletView,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        }
    },
    {
        path: '/demographics',
        name: 'demographics',
        component: DemographicsView,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        }
    },
    {
        path: '/dependents',
        name: 'Dependents',
        component: Dependents,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        }
    },

    {
        path: '/privacy',
        name: 'Privacy',
        component: PrivacyView,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        }
    },

    {
        path: '/support',
        name: 'support',
        component: Support,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        }
    },
    {
        path: '/noAuthSupport',
        name: 'noAuthSupport',
        component: NoAuthSupport,
        meta: {
            auth: false,
            disableFooter: true,
            disableHeader: true,
        }
    },
    {
        path: '/activeTicket',
        name: 'activeTicket',
        component: ActiveTicket,
        meta: {
            auth: false,
            disableFooter: true,
            disableHeader: true,
        }
    },
    {
        path: '/accounts',
        name: 'accounts',
        component: Accounts,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        }
    },
    {
        path: '/idcard',
        name: 'idcard',
        component: IDCard,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        }
    },
    {
        path: '/telemedicine',
        name: 'telemedicine',
        component: Telemedicine,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        }
    },
    {
        path: '/claims',
        name: 'claims',
        component: Claims,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        }
    },
    {
        path: '/claim/:claimId',
        name: 'claim',
        component: SingleClaim,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        },
        props: true
    },

    {
        path: '/rxclaim/:claimId',
        name: 'rxclaim',
        component: RxClaim,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        },
        props: true
    },

    {
        path: '/claimNumber/:claimNumber',
        name: 'claimNumberRouter',
        component: () => import(/*webpackChunkName:'claims'*/ '@/views/ClaimNumberRouter.vue'),
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        },
        props: true
    },
    {
        path: '/eob/:claimId',
        name: 'eob',
        component: EOBView,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        },
        props: true
    },
    {
        path: '/medicalhistory',
        name: 'medicalhistory',
        component: MedicalHistory,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        }
    },
    {
        path: '/rightway',
        name: 'rightway',
        component: RightwayView,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        }
    },
    {
        path: '/rightway/chat',
        name: 'rightwayChat',
        component: RightwayChatView,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        }
    },
    {
        path: '/rightway/billsupport',
        name: 'rightwayBillSupport',
        component: RightwayBillSupportView,
        meta: {
            auth: true,
            showBack: true,
            showDots: false,
            disableHeader: false,
        }
    },
    {
        path: '/rightway/submitbillsupport/:claimId',
        name: 'rightwaySubmitBillSupport',
        component: RightwaySubmitBillView,
        meta: {
            auth: true,
            showBack: true,
            showDots: false,
        },
        props: true
    },

    {
        path: '/avatar',
        name: 'avatar',
        component: ManageAvatarView,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
            backLocation: '/'
        }
    },
    {
        path: '/avatar/upload',
        name: 'avatarUpload',
        component: AvatarUploadView,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
            backLocation: '/avatar'
        }
    },

    {
        path: '/notification/settings',
        name: 'notificationSettings',
        component: NotificationSettings,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
            backLocation: '/settings'
        }
    },

    {
        path: '/plan',
        name: 'currentPlanDetails',
        component: PlanDetails,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
        }
    },

    {
        path: '/optimity/survey/:surveyId',
        name: 'optimitySurvey',
        component: OptimitySurveyView,
        meta: {
            auth: true,
            showBack: false,
            showDots: true,
            disableHeader: false,
            disableFooter: true,

        },
        props: true
    },

    {
        path: '/optimity/surveys',
        name: 'optimitySurveys',
        component: OptimitySurveyListView,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
            disableHeader: false,
            disableFooter: false,
        },
        props: true
    },


    {
        path: '/optimity/challenge',
        name: 'optimityChallenge',
        component: OptimityChallengeView,
        meta: {
            auth: true,
            showBack: false,
            showDots: true,
            disableHeader: false,
            disableFooter: true,
        },

    },

    {
        path: '/optimity/redeemrewards',
        name: 'optimityRedeemRewards',
        component: OptimityRewardsRedemptionView,
        meta: {
            auth: true,
            showBack: false,
            showDots: true
        }
    },

    {
        path: '/optimity/earnrewards',
        name: 'optimityEarnRewards',
        component: OptimityEarnRewardsView,
        meta: {
            auth: true,
            showBack: true,
            showDots: true
        }
    },


    {
        path: '/iterable/message/:messageId',
        name: 'iterableMessageView',
        component: IterableInboxMessageView,
        meta: {
            auth: true,
            showBack: true,
            showDots: true,
            disableHeader: false,
            disableFooter: false,
        },
        props: true
    },


    {
        path: '/notifications',
        name: 'Notifications',
        component: () => import(/*webpackChunkName:'notifications'*/ '@/views/NotificationsView.vue'),
        meta: {
            auth: true,
            showBack: true,
            showDots: true
        }
    },

    {
        path: '/notices',
        name: 'Notices',
        component: () => import(/*webpackChunkName:'notices'*/ '@/views/NoticesView.vue'),
        meta: {
            auth: true,
            showBack: true,
            showDots: true
        }
    },

    {
        path: '/rxServices',
        name: 'RxServices',
        component: RxServicesView,
        meta: {
            auth: true,
            showBack: true,
            showDots: true
        }
    },

    {
        path: '/hhtmsa',
        name: 'MSA',
        component: MSAView,
        meta: {
            auth: true,
            showBack: true,
            showDots: true
        }
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// @ts-ignore
router.goBackOrHome = () => {
    const backUrl: string = get(router, 'options.history.state.back', 'NOBACKROUTE').toString();
    if (backUrl == 'NOBACKROUTE') {
        router.push('/');
    } else {
        router.back();
    }

};

router.beforeEach(async (to) => {

    const authStore = useAuthStore(pinia);
    // @ts-ignore
    await authStore.restored;
    //@ts-ignore
    const toPath = get(to, 'path', '');
    if (
        get(to, 'meta.auth', false)
        &&
        !authStore.isAuthenticated
        &&
        !['/login', '/logout'].includes(toPath)
    ) {
        // redirect the user to the login page
        authStore.intendedRoute = toPath;
        return {path: '/login'}
    }

    if (
        authStore.locked === true
        &&
        !['/unlock', '/logout'].includes(toPath)
    ) {
        authStore.intendedRoute = toPath;
        // redirect the user to the login page
        return {path: '/unlock'}
    }


});

router.afterEach(async (to) => {
    const authStore = useAuthStore(pinia);
    if (to.path === authStore.intendedRoute) {
        authStore.intendedRoute = '';
    }
});

export default router
