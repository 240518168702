import {defineStore} from 'pinia'
import axios from "axios";
import * as sdk from "matrix-js-sdk";
import {useAuthStore} from "@/stores/authStore";
import {v4} from 'uuid';
import {get} from 'lodash-es';
// @ts-ignore
export const useMatrixStore = defineStore('matrix', {
    state: () => ({
        accessToken: '',
        apiUrl: 'https://matrix.hht.llc/_matrix/client/',
        chatActive: false,
        chatLocationClass: 'bottom-two-thirds',
        deviceId: '',
        homeServer: '',
        jwt: '',
        jwtExpires: 0,
        userId: '',
        authenticated: false,
        activeRoomId: '',
        partnerTyping: false,
        typingPartner: '',
        messages: [],
        unreadMessages: 0,
        lastReadTime: 0,
        userDirectory: []
    }),
    actions: {
        cleanRooms(everything = false) {

            //@ts-ignore
            if (!get(window, 'matrixChat.store.rooms', false)) {
                return;
            }

            if (everything) {
                this.activeRoomId = '';
            }

            //@ts-ignore
            Object.keys(window.matrixClient.store.rooms).forEach((roomId) => {
                //@ts-ignore
                console.info('Active Room:', this.activeRoomId, 'This Room', roomId);
                if (roomId !== this.activeRoomId) {
                    console.info('here is a room');
                    //@ts-ignore
                    const members = window.matrixClient.store.rooms[roomId].getMembers();
                    console.info('room members', members);

                    //@ts-ignore
                    members.forEach((member: any) => {
                        if (this.userId !== member.userId) {

                            console.info('kicking member from room', roomId, member.userId);
                            //@ts-ignore
                            window.matrixClient.kick(roomId, member.userId);
                        }
                    });

                    members.forEach((member: any) => {
                        if (this.userId === member.userId) {
                            //@ts-ignore
                            window.matrixClient.leave(roomId);
                        }
                    });

                    //@ts-ignore
                    // window.matrixClient.forget(roomId, true);

                }

            });
        },
        async fetchJwt() {

            const authStore = useAuthStore();
            const uuid = get(authStore.activePlan, 'memberId', false);


            if (!uuid) {
                alert('no uuid');
                console.log(authStore);
                return;
            }
            await axios.get(`https://api.herculeshealth.app/api/matrix/jwt?uuid=${uuid}`).then(({data}) => {

                this.jwt = data.token;
                this.jwtExpires = data.expiry;
            });

        },
        resetChat() {
            this.messages = [];
            this.cleanRooms(true);
            this.activeRoomId = '';
            this.chatActive = false;
            this.userDirectory = [];
            this.unreadMessages = 0;
            this.lastReadTime = 0;
            //@ts-ignore
            delete window.matrixClient;

            this.authenticated = false;
            this.startChat();
        },
        toggleChat() {
            this.chatActive = !this.chatActive;

            if (this.chatActive) {
                this.unreadMessages = 0;
                this.startChat();
            }
        },
        setChatLayout(layoutClass: string) {
            this.chatLocationClass = layoutClass;
        },

        sendCommand(message: string) {

            const regexp = /\/(\w+)/ig;
            const matches = message.match(regexp);
            if (matches) {
                console.log('found command', matches[0]);

                switch (matches[0]) {
                    case '/clear':
                        this.messages = [];
                        break;
                    case '/reset':
                        this.resetChat();
                        break;
                    case '/leave':
                        //@ts-ignore
                        window.matrixClient.leave(this.activeRoomId);
                        this.chatActive = false;
                        break;
                    case '/membership':
                        //@ts-ignore
                        console.info(window.matrixClient.store.rooms[this.activeRoomId].getJoinedMembers());
                        break;
                    case '/reinvite':
                        this.inviteDefaultMembers();
                        break;
                    case '/rooms':
                        //@ts-ignore
                        console.info(window.matrixClient.store.rooms);
                        break;
                }

            }

        },

        sendMessage(message: any) {

            if (message.startsWith('/')) {
                this.sendCommand(message);
                return;
            }

            this.inviteDefaultMembers();

            const content = {
                body: message,
                msgtype: "m.text",
            };

            // @ts-ignore
            window.matrixClient.sendEvent(this.activeRoomId, "m.room.message", content, "", (err: any, res: any) => {
                console.info(err, res);
            });

        },

        async inviteDefaultMembers() {
            //@ts-ignore
            window.matrixClient.invite(this.activeRoomId, '@adietz:matrix.hht.llc');

            //@ts-ignore
            window.matrixClient.invite(this.activeRoomId, '@hhtmeg:matrix.hht.llc');

            //@ts-ignore
            window.matrixClient.invite(this.activeRoomId, '@alex:matrix.hht.llc');
        },

        async startChat() {
            console.info('starting chat');
            const authStore = useAuthStore();
            //@ts-ignore
            if (!window.matrixClient) {
                console.info('no matrix client');
                await this.login();
            }
            if (!this.activeRoomId) {
                console.log('no active room');
                //@ts-ignore
                const rooms = get(window, 'matrixClient.store.rooms', []);

                if (Object.keys(rooms).length <= 0) {
                    this.activeRoomId = `!${v4()}:matrix.hht.llc`;
                    //@ts-ignore
                    const {room_id: roomId} = await window.matrixClient.createRoom(
                        {
                            // @ts-ignore
                            name: `Chat with ${authStore.userData.profile.first_name} ${authStore.userData.profile.last_name}`
                        });
                    this.activeRoomId = roomId;
                    //@ts-ignore
                    // window.matrixClient.joinRoom(this.activeRoomId);
                    this.inviteDefaultMembers();
                } else {
                    this.activeRoomId = Object.keys(rooms)[0];
                    //@ts-ignore
                    window.matrixClient.joinRoom(this.activeRoomId);
                    this.inviteDefaultMembers();
                }

            }

            //@ts-ignore
            window.matrixClient.setDisplayName(`App User ${get(authStore.userData, 'profile.first_name')} ${get(authStore.userData, 'profile.last_name')}`);

            this.cleanRooms();

            // @ts-ignore
            // console.info('matrix client', window.matrixClient);
            //
            // if (!this.activeRoomId) {
            //     const roomId = `!${v4()}:${this.homeServer}`;
            //     //@ts-ignore
            //     window.matrixClient.createRoom({_roomId: roomId});
            //     //@ts-ignore
            //     window.matrixClient.joinRoom(roomId);
            //     this.activeRoomId = roomId;
            // }
        },

        setup() {
            //@ts-ignore
            if (window.matrixClient) {
                return;
            }

            this.login();
        },
        async login() {
            console.info('starting login');
            if (this.authenticated) {
                console.info('already authenticated');
                return;
            }
            await this.fetchJwt();
            const loginData = {
                "type": "org.matrix.login.jwt",
                "token": this.jwt
            };
            console.log('logging in with login data', loginData);
            axios.post(`${this.apiUrl}r0/login`, loginData, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(({data}) => {
                this.userId = data.user_id;
                this.accessToken = data.access_token;
                this.homeServer = data.home_server;
                this.deviceId = data.device_id;
                this.authenticated = true;
                //@ts-ignore
                window.matrixClient = sdk.createClient(
                    {
                        baseUrl: "https://matrix.hht.llc",
                        accessToken: this.accessToken,
                        userId: `${this.userId}`
                    }
                );

                //@ts-ignore
                window.matrixClient.startClient({initialSyncLimit: 10});

                //@ts-ignore
                window.matrixClient.on("RoomMember.typing", (event, member) => {
                    console.info('RoomMember.typing client event', event, member);
                    if (member.userId === this.userId) {
                        return;
                    }
                    if (member.typing) {
                        this.typingPartner = member.name;
                        this.partnerTyping = true;
                    } else {
                        this.typingPartner = '';
                        this.partnerTyping = false;
                    }
                });

                //@ts-ignore
                window.matrixClient.on('RoomMember.membership', async (event, member) => {
                    console.info('New Member Joined Room', member);
                    //@ts-ignore
                    if (this.userDirectory.find((a) => a.userId === member.userId)) {
                        return;
                    }
                    if (member.user) {
                        member.user.http_user_avatar = member.getAvatarUrl('https://matrix.hht.llc');
                    }

                    // @ts-ignore
                    this.userDirectory.push(member);

                });

                //@ts-ignore
                window.matrixClient.on("Room.timeline", (event) => {

                    if (event.getType() === "m.room.sizeAdjust") {
                        alert('resize event');
                        console.log(event);
                    }

                    if (event.getType() !== "m.room.message") {
                        return; // only use messages
                    }
                    console.info('New Message Received', event.event);

                    // @ts-ignore
                    this.messages.push({
                        ...event.event.content,
                        eventId: event.event.event_id,
                        sender: event.event.sender,
                        timeStamp: event.event.origin_server_ts
                    });

                    if (this.chatActive) {
                        this.lastReadTime = event.event.origin_server_ts;
                    } else {
                        if (this.lastReadTime < event.event.origin_server_ts) {
                            this.unreadMessages++;
                        }
                    }
                });

            }).catch((error) => {
                console.error(error);
            });
        }
    },
    getters: {
        activeRoom(): any {
            if (!this.activeRoomId) {
                return {};
            }
            if (!get(window, 'matrixChat.store.rooms', false)) {
                return {};
            }
            //@ts-ignore
            return window.matrixClient.store.rooms[this.activeRoomId];
        }
    }

})
