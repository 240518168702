<template>

  <div class="hercInput">

    <div class="row">
      <div class="icon prependIcon"
           v-if="props.prependIcon">
        <component
            :is="props.prependIcon"
            :fill-color="props.prependIconFillColor"
        ></component>
      </div>

      <template v-if="type=='textarea'">
        <ion-textarea
            v-model="localValue"
            :placeholder="label"
            :autoGrow="autogrow"
            :rows="rows"
            v-bind="$attrs"
            ref="inputElementTextarea"
            @keyup.enter.stop="emit('keyup.enter')"
            @keydown.stop="emit('keydown')"
        ></ion-textarea>
      </template>
      <template v-else-if="type=='date'">
        <MqResponsive target="sm-">
          <div class="datePlaceHolder"
               @click="datePickerActive=!datePickerActive"
          >
            <span class="placeholder" v-if="!localValue">{{ props.label }}</span>
            <span class="realValue" v-if="localValue">{{ displayDate }}</span>
          </div>

          <CustomDatePicker
              v-model="localValue"
              :picker-active="datePickerActive"
              @cancel="datePickerActive = false"
              @change="datePickerActive = false"
              :label="label"
          ></CustomDatePicker>
        </MqResponsive>

        <MqResponsive target="md+">
          <IonItem>
            <IonLabel position="floating">{{ label }}</IonLabel>
            <IonInput
                v-model="localValue"
                :placeholder="label"
                type="date"
                color="secondary"
                ref="inputElementLarge"
                v-bind="$attrs"
                @keyup.enter.stop="emit('keyup.enter')"
                @keydown.stop="emit('keydown')"
            >
              <template v-slot:end>
                <slot name="end"></slot>
              </template>
            </IonInput>
          </IonItem>
        </MqResponsive>
      </template>
      <template v-else>
        <IonInput
            :label="label"
            :placeholder="placeholder"
            v-model="localValue"
            ref="inputElementDefault"
            :inputmode="inputmode"
            :type="type"
            color="secondary"
            v-bind="$attrs"
            @keyup.enter.stop="emit('keyup.enter')"
            @keydown.stop="emit('keydown')"
        >
          <template #end>
            <slot name="end"></slot>
          </template>

        </IonInput>
      </template>

      <div
          class="icon appendIcon"
          v-if="appendIcon"
          @click="handleAppendIconClick">
        <component
            :is="appendIcon"
            :fill-color="appendIconFillColor"
        ></component>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps, ref, defineExpose, defineSlots, onMounted} from "vue";

import {
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea
} from "@ionic/vue";

import CustomDatePicker from '@/components/CustomDatePicker';
import {MqResponsive} from "vue3-mq";


const inputElementTextarea = ref(null);
const inputElementLarge = ref(null);
const inputElementDefault = ref(null);
const datePickerActive = ref(false);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const slots = defineSlots();
const localValue = computed({
  get: () => props.modelValue ? props.modelValue : '',
  set: (value) => emit('update:modelValue', value || '')
});


function setFocus() {


  // check every 250 milliseconds if the input is available.  if it is, focus on it.  only check for 12 seconds, though
  let counter = 0;
  const interval = setInterval(() => {
    // determine which input element to focus on
    let inputElement = {};

    if (inputElementDefault.value) {
      inputElement = inputElementDefault.value;
    }
    if (inputElementTextarea.value) {
      inputElement = inputElementTextarea.value;
    }
    if (inputElementLarge.value) {
      inputElement = inputElementLarge.value;
    }


    if (Object.hasOwnProperty.bind(inputElement)('$el')) {
      clearInterval(interval);
      inputElement.$el.setFocus();
    }

    counter++;

    if (counter > 48) {
      clearInterval(interval);
    }
  }, 250);


}

defineExpose({
  setFocus
});

const props = defineProps({
  modelValue: {},
  autogrow: {
    type: Boolean,
  },
  rows: {
    type: Number,
    default() {
      return 4;
    }
  },
  inputmode: {
    type: String,
    default() {
      return 'text';
    }
  },
  label: {
    type: String,
    default() {
      return '';
    }
  },
  placeholder: {
    type: String,
    default() {
      return '';
    }
  },
  autocomplete: {
    type: String,
    default() {
      return '';
    }
  },
  type: {
    default() {
      return 'text';
    }
  },
  prependIcon: {
    default() {
      return false;
    }
  },
  appendIcon: {
    default() {
      return false;
    }
  },
  prependIconFillColor: {
    default() {
      return 'black';
    }
  },
  appendIconFillColor: {
    default() {
      return 'black';
    }
  }
});
const emit = defineEmits(['update:modelValue', 'append-icon-clicked', 'keyup.enter', 'keydown']);


function handleAppendIconClick() {
  emit('append-icon-clicked');
}

const displayDate = computed(() => {
  if (props.type === 'date') {
    if (localValue.value.split('-').length < 3) {
      return '';
    }

    const year = localValue.value.split('-')[0];
    const month = localValue.value.split('-')[1];
    const day = localValue.value.split('-')[2];

    return `${month}/${day}/${year}`;
  }
  return '';
});


</script>

<style scoped lang=scss>


.dateTime {
  z-index: 100000;
}

.datePlaceHolder {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 38px;

  .placeholder {
    color: var(--ion-color-secondary);
    opacity: 50%;
    font-size: 16px;
    font-family: var(--ion-font-family, inherit);

  }

}

.hercInput {
  margin: 1rem 0;
  border-radius: 10px;
  font-family: var(--ion-font-family);
  background-color: #ffffff;
  padding: 5px 10px;

  .row {
    display: flex;
    width: 100%;
    min-height: 32px;

    position: relative;
  }

  input {
    flex-grow: 1;
    border: none;
    font-size: 16px;
    line-height: 16px;
    height: 26px;
    padding: 0;
    background-color: #ffffff;
    color: var(--ion-color-secondary);
    font-family: var(--ion-font-family);

  }

  .prependIcon {
    margin-right: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .appendIcon {
    margin-left: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  label {
    margin-bottom: -10px;
    height: 10px;
    font-size: 10px;
    font-family: var(--ion-font-family);
    position: absolute;
    top: 0;
  }
}
</style>
