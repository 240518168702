<template>
  <div class="appHeaderMenuItem" @click="handleClick">
    <div class="text">{{ text }}</div>
    <div class="icon">
      <slot name="icon"></slot>
    </div>
  </div>
</template>

<script>


import {Haptics, ImpactStyle} from "@capacitor/haptics";
import {Capacitor} from "@capacitor/core";

export default {
  name: 'AppHeaderMenuItem',
  components: {},
  props: {
    text: {
      required: true,
      type: String,
    },
    to: {
      type: String,
      default() {
        return '';
      }
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleClick() {
      if (Capacitor.isNativePlatform()) {
        Haptics.impact({style: ImpactStyle.Medium});
      }
      if (this.to) {
        this.$router.push(this.to).catch(() => {
          // Catch Error
        });
        this.$emit('route');
      }
    }

  },

};
</script>

<style scoped lang="scss">
.appHeaderMenuItem {
  display: flex;
  justify-content: right;
  color: white;
  margin-top: 1.5rem;
  -webkit-justify-content: flex-end;

  cursor: pointer;

  @media (min-width: 721px) {
    margin-top: .5rem;

  }

  .text {
    font-size: 24px;
    color: white;
    margin-right: 1rem;

    @media (min-width: 721px) {
      font-size: 18px;
    }
  }

  .icon {
    @media (max-width: 599px) {
      padding-top: 4px;
    }

  }


}
</style>
